<script setup lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { ref, defineProps, defineEmits, onMounted } from 'vue'
import { OptionProps } from 'ant-design-vue/es/select'
import { message } from 'ant-design-vue'
import { debounce } from 'lodash'

const props = defineProps({
    value: {
        type: String,
        required: true,
    },
    dataList: {
        type: Array,
    },
})

const emits = defineEmits(['update:value', 'select'])

const presetList = ref<OptionProps[]>([])

const initPreset = async () => {
    const response = await adminApi<ApiResponse<any[]>>(
        `/api/forme/diet/prompt-preset?presetType=FORME_PROMPT`
    )
    presetList.value =
        response.result?.map((preset) => ({
            value: preset.promptList[0],
            label: preset.name,
            dataList: preset.dataList,
        })) || []
}

const fetchPreset = debounce(async (value: string) => {
    try {
        const response = await adminApi<ApiResponse<any[]>>(
            `/api/forme/diet/prompt-preset/${value}?presetType=FORME_PROMPT`
        )
        presetList.value =
            response.result?.map((preset) => ({
                value: preset.promptList[0],
                label: preset.name,
                dataList: preset.dataList,
            })) || []
    } catch (error) {
        console.error(error)
        message.error('프리셋 정보를 불러오는데 실패했습니다.')
    }
}, 500)

const searchText = ref('')

const onSearch = async (value: string) => {
    await fetchPreset(value)
}

const selectedOption = ref<OptionProps | null>(null)

const onSelect = (value: string, option: Record<string, any>) => {
    selectedOption.value = option
    prompt.value = value
    searchText.value = option.label
    emits('update:value', value)
    emits('select', value, option)
}

const prompt = ref(props.value)

const onUpdate = (value: string) => {
    prompt.value = value
    emits('update:value', value)
}

const loading = ref(false)
const onSave = async () => {
    try {
        loading.value = true
        await adminApi<ApiResponse<any>>(`/api/forme/diet/prompt-preset`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: searchText.value,
                presetType: 'FORME_PROMPT',
                promptList: [prompt.value],
                dataList: props.dataList,
            }),
        })
    } catch (error) {
        console.error(error)
        message.error('프리셋 저장에 실패했습니다.')
    } finally {
        loading.value = false
    }
}

const onDelete = async () => {
    try {
        loading.value = true
        await adminApi<ApiResponse<any>>(
            `/api/forme/diet/prompt-preset/${searchText.value}?presetType=FORME_PROMPT`,
            {
                method: 'DELETE',
            }
        )
    } catch (error) {
        console.error(error)
        message.error('프리셋 삭제에 실패했습니다.')
    } finally {
        loading.value = false
    }
}

onMounted(() => {
    initPreset()
})
</script>

<template>
    <a-space direction="vertical" class="w-[100%]">
        <a-auto-complete
            v-model:value="searchText"
            :options="presetList"
            @search="onSearch"
            @select="onSelect"
        />
        <a-textarea :value="value" @update:value="onUpdate" :rows="10" />
        <div class="flex justify-end">
            <a-space-compact>
                <a-button
                    @click="onDelete"
                    :loading="loading"
                    :disabled="!searchText"
                    danger
                    >삭제</a-button
                >
                <a-button
                    type="primary"
                    @click="onSave"
                    :disabled="!searchText || !prompt"
                    :loading="loading"
                    >저장</a-button
                >
            </a-space-compact>
        </div>
    </a-space>
</template>
