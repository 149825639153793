import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"margin-left":"1em"}
}
const _hoisted_2 = {
  key: 0,
  style: {"margin-left":"1em"}
}
const _hoisted_3 = { style: {"margin-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.mode)
      ? (_openBlock(), _createBlock(_component_a_form_item, {
          key: 0,
          name: _ctx.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              options: _ctx.options,
              value: _ctx.value || [],
              mode: _ctx.mode,
              onChange: _ctx.change,
              onSelect: _ctx.onSelect,
              onSearch: _ctx.onSearch,
              "default-active-first-option": false,
              "not-found-content": null,
              "filter-option": false,
              style: _normalizeStyle({ width: _ctx.width }),
              "dropdown-match-select-width": 500,
              placeholder: _ctx.placeholder,
              onPressEnter: _ctx.onPressEnter,
              "show-search": ""
            }, null, 8, ["options", "value", "mode", "onChange", "onSelect", "onSearch", "style", "placeholder", "onPressEnter"]),
            (_ctx.countable)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                  _createVNode(_component_a_badge, { count: _ctx.count }, null, 8, ["count"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["name"]))
      : (_openBlock(), _createBlock(_component_a_form_item, {
          key: 1,
          name: _ctx.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_auto_complete, _mergeProps({
              options: _ctx.options,
              value: _ctx.value,
              onChange: _ctx.change,
              onSelect: _ctx.onSelect,
              onSearch: _ctx.onSearch,
              placeholder: _ctx.placeholder,
              style: { width: _ctx.width },
              "dropdown-match-select-width": 500,
              onPressEnter: _ctx.onPressEnter
            }, _ctx.$attrs), null, 16, ["options", "value", "onChange", "onSelect", "onSearch", "placeholder", "style", "onPressEnter"]),
            (_ctx.countable)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createVNode(_component_a_badge, { count: _ctx.count }, null, 8, ["count"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_3, [
              _renderSlot(_ctx.$slots, "ext")
            ])
          ]),
          _: 3
        }, 8, ["name"]))
  ]))
}