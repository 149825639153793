import { ref } from 'vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { formatKoreanTime } from '@/util/formmater'
import { format } from 'date-fns'
import { message as log } from 'ant-design-vue'

type MessageType = {
    id: string
    message: string
    time: string
    isLeft?: boolean
    srcList?: Array<string>
    mediaType?: 'IMAGE' | 'VIDEO' | 'HTML'
    messageType?: string
    profileImageUrl?: string
    userName?: string
    coachName?: string
    coachProfileImageUrl?: string
    needHr?: boolean
    creationTime?: Date
    loading?: boolean
    read: boolean
    questionId?: number
    questionUserName?: string
    questionMessage?: string
    firstAnswerId?: string
    emojiList?: string[]
    pushSent?: boolean
}

type Timeline = {
    title: string
    date: string
}

const unreadCount = ref<number>(0)

const fetchUnreadCount = async (userId: number) => {
    const response = await adminApi<ApiResponse<number>>(
        `/api/forme/diet/chat/unread/${userId}`
    )
    unreadCount.value = response.result || 0
}

const readAllMessage = async (userId: number) => {
    await adminApi<ApiResponse<null>>(`/api/forme/diet/chat/read/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

const messageList = ref<Array<MessageType>>([])
const timelineList = ref<Array<Timeline>>([])

const fetchMessageList = async (userId: number) => {
    const response = await adminApi<ApiResponse<any>>(
        `/api/forme/diet/chat/${userId}`
    )
    const existsIds = messageList.value.map((m) => m.id)
    const result = response.result.filter(
        (it: any) => !existsIds.includes(it.id)
    )
    result.forEach((it: any, index: number) => {
        let dateChanged = false
        if (index > 0) {
            const prev = result[index - 1]
            if (
                format(prev.creationTime, 'yyyy-MM-dd') !==
                format(it.creationTime, 'yyyy-MM-dd')
            ) {
                dateChanged = true
            }
        }

        messageList.value.push({
            id: it.id,
            message: it.message,
            time: formatKoreanTime(it.creationTime),
            isLeft: it.byUser,
            srcList: it.mediaList.map((m: any) => m.url),
            messageType: it.formeMessageType,
            mediaType: it.formeMessageContentType,
            profileImageUrl: it.userProfileImageUrl,
            userName: it.userUserName,
            coachName: it.coachUserName,
            coachProfileImageUrl: it.coachProfileImageUrl,
            needHr: dateChanged,
            creationTime: it.creationTime,
            read: it.isMarked,
            questionId: it.questionId,
            questionUserName: it.questionUserName,
            questionMessage: it.questionMessage,
            firstAnswerId: it.firstAnswerId,
            emojiList: it.emojiList,
            pushSent: it.pushSent,
        })
    })
    const groupByDate = result.reduce((acc: any, it: any) => {
        const date = format(it.creationTime, 'yyyy-MM-dd')
        if (acc[date] == null) {
            acc[date] = []
        }
        acc[date].push(it)
        return acc
    }, {})

    timelineList.value.length = 0
    timelineList.value.push(
        ...Object.entries(groupByDate).map(([date, list]: [string, any]) => ({
            title: list[0].message,
            date,
        }))
    )
}

const loadingMessage = ref(false)

const writeMessage = async (
    userId: number,
    formeMesageType: string,
    formeMessageContentType: string,
    message?: string,
    questionId?: string
) => {
    loadingMessage.value = true
    try {
        await adminApi(`/api/forme/diet/message`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
                formeMessageType: formeMesageType,
                formeMessageContentType,
                message,
                targetDate: format(new Date(), 'yyyy-MM-dd'),
                questionId,
            }),
        })
    } catch (e) {
        console.error(e)
        log.error('메세지 전송에 실패했습니다.')
    }
    loadingMessage.value = false
}

const cleanMessageList = () => {
    messageList.value.length = 0
    timelineList.value.length = 0
}

const qnaEnable = ref(false)

const fetchQnaEnable = async (userId: number) => {
    const response = await adminApi<ApiResponse<boolean>>(
        `/api/forme/diet/chat/qna/enable/${userId}`
    )
    qnaEnable.value = response.result || false
}

const selectedMessage = ref<MessageType | null>(null)

const selectMessage = (message: MessageType) => {
    if (message.isLeft && message.messageType === 'QNA') {
        selectedMessage.value = message
    }
}

const deselectMessage = () => {
    selectedMessage.value = null
}

const updateMessage = async (
    messageId: string,
    message: string,
    callback: () => void
) => {
    try {
        await adminApi(`/api/forme/diet/message/${messageId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                message: message,
            }),
        })
    } catch (e) {
        console.error(e)
        log.error('메세지 수정에 실패했습니다.')
        throw e
    } finally {
        callback()
    }
}

const deleteMessage = async (messageId: string, callback: (e: any) => void) => {
    try {
        await adminApi(`/api/forme/diet/message/${messageId}`, {
            method: 'DELETE',
        })
    } catch (e) {
        console.error(e)
        log.error('메세지 삭제에 실패했습니다.')
        callback(e)
        throw e
    } finally {
        callback(null)
    }
}

export default {
    unreadCount,
    fetchUnreadCount,
    readAllMessage,
    messageList,
    fetchMessageList,
    timelineList,
    writeMessage,
    loadingMessage,
    cleanMessageList,
    fetchQnaEnable,
    qnaEnable,
    selectedMessage,
    selectMessage,
    deselectMessage,
    updateMessage,
    deleteMessage,
}
