<template>
    <ResourceTable
        resource-name="events"
        title="AI Config"
        uri="/api/forme/diet/configs"
        :columns="columns"
        :create-spec="inputSpec"
        :update-spec="inputSpec"
        :create-rules="rules"
        :update-rules="rules"
        :pagination="{ pageSize: 50 }"
        load-on-mount
    >
    </ResourceTable>
</template>

<script setup lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { Column } from '.'
import { formatLocalDateTime } from '@/util/formmater'
import { ref, onMounted, Ref } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'
import globalFormState from '@/components/globalFormState'

const columns: Array<Column> = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 30,
    },
    {
        title: '이름',
        dataIndex: 'name',
        width: 50,
    },
    {
        title: '값',
        dataIndex: 'value',
        width: 50,
    },
    {
        title: '파일경로',
        dataIndex: 'filePath',
        width: 50,
    },
]

const inputSpec = ref<FormSpecification>({
    id: {
        label: 'ID',
        value: null,
        readonly: true,
    },
    name: { label: '이름', value: null },
    typeKey: { label: 'Key', value: null },
    value: { label: '값', value: null },
    filePath: {
        label: '첨부파일',
        value: null,
        component: InputComponents.ContentImageInput,
        path: '/ai-file',
    },
})

const rules = {
    name: [required],
    key: [required],
    filePath: [required],
}

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
})
</script>
