import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "editor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_bar = _resolveComponent("menu-bar")!
  const _component_editor_content = _resolveComponent("editor-content")!

  return (_ctx.editor)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showMenu)
          ? (_openBlock(), _createBlock(_component_menu_bar, {
              key: 0,
              class: "editor__header",
              editor: _ctx.editor
            }, {
              "custom-item": _withCtx(() => [
                _renderSlot(_ctx.$slots, "custom-item")
              ]),
              _: 3
            }, 8, ["editor"]))
          : _createCommentVNode("", true),
        _createVNode(_component_editor_content, {
          class: "editor__content",
          editor: _ctx.editor
        }, null, 8, ["editor"])
      ]))
    : _createCommentVNode("", true)
}