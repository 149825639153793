<template>
    <modal-button
        ref="modalButtonRef"
        v-bind="{ ...$props, ...$attrs }"
        @submit="post"
        @after-show="showModal"
        :shape="shape"
    >
        <template #icon>
            <slot name="icon" />
        </template>
        <template #beforeButton="data">
            <slot name="beforeButton" v-bind="data" />
        </template>
        <template #button> 실행 </template>
        <template #header="data">
            <slot name="header" v-bind="data" />
        </template>
        <template #footer="data">
            <slot name="footer" v-bind="data" />
        </template>
    </modal-button>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import ModalButton from '@/components/modal/ModalButton.vue'
import { defineComponent, ref } from 'vue'
import globalFormState from '@/components/globalFormState'

export default defineComponent({
    name: 'ActionModal',
    extends: ModalButton,

    props: {
        inputSpec: {
            type: Object,
            required: true,
        },
        shape: {
            type: String,
        },
    },

    components: {
        ModalButton,
    },

    setup() {
        const modalButtonRef = ref()
        return { ...globalFormState, modalButtonRef }
    },

    emits: ['showModal', 'afterSubmit'],

    methods: {
        async post() {
            const { toggleLoading, toggleVisible, setErrorMessage, alertFail } =
                this.modalButtonRef
            toggleLoading()
            try {
                const response = await adminApi<ApiResponse<void>>(
                    `${this.uri}/${this.resourceId}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ...this.form,
                            id: this.resourceId,
                        }),
                    }
                )
                toggleLoading()

                if (
                    response.success === undefined ||
                    response.success === false
                ) {
                    setErrorMessage(
                        response.errorMessage ||
                            '예외 발생 - 개발자에게 문의하세요'
                    )
                } else {
                    this.$emit('afterSubmit')
                    this.resetForm()
                    toggleVisible()
                }
            } catch (error) {
                const err = error as Error
                toggleLoading()
                setErrorMessage(err.message)
                alertFail()
                throw error
            }
        },
        showModal() {
            if (this.record != null) {
                this.setFormValues(this.record)
            }

            this.$emit('showModal', {
                setFormValues: this.setFormValues,
                resourceId: this.resourceId,
            })
        },
    },
})
</script>
