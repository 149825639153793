<template>
    <div class="editor" v-if="editor">
        <menu-bar v-if="showMenu" class="editor__header" :editor="editor">
            <template #custom-item>
                <slot name="custom-item" />
            </template>
        </menu-bar>
        <editor-content class="editor__content" :editor="editor" />
    </div>
</template>

<script lang="ts">
//@ts-nocheck

import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Link from '@tiptap/extension-link'
import TiptapImage from '@tiptap/extension-image'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import MenuBar from './TiptapMenuBar.vue'
import { defineComponent } from 'vue'
import { baseUrl } from '@/fetchTemplate'
import { stringify } from 'qs'
import { fileToWebp } from '@/util/image'
import { ResizableMedia } from '@/components/input/tiptap/resizableMedia'
import { AutoResizableMediaExtension } from './AutoResizableMediaExtension'

const uploadImage = async (file: File) => {
    const formData = new FormData()
    formData.append('file', await fileToWebp(file))

    const params = {
        path: 'tiptap',
    }
    const uploadUrl = `${baseUrl}/api/file?${stringify(params)}`
    const response = await fetch(uploadUrl, {
        method: 'POST',
        credentials: 'include',
        body: formData,
    })
    const jsonBody = await response.json()
    return jsonBody.result
}

export default defineComponent({
    name: 'TipTap',
    components: {
        EditorContent,
        MenuBar,
    },

    props: {
        value: {
            type: String,
            default: '',
        },
        showMenu: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            editor: null,
        }
    },

    emits: ['update:value', 'change'],

    watch: {
        value(value: any) {
            const isSame = this.editor?.getHTML() === value

            if (isSame) {
                return
            }

            this.editor?.commands.setContent(value, false)
        },
    },

    mounted() {
        const editor = new Editor({
            extensions: [
                StarterKit,
                Highlight,
                TaskList,
                TaskItem,
                TiptapImage.configure({ inline: true }),
                Link.configure({
                    protocols: ['https', 'mailto', 'ftp', 'pillyze'],
                }),
                CharacterCount.configure({
                    limit: 10000,
                }),
                ResizableMedia,
                AutoResizableMediaExtension,
            ],
            content: this.value,
            onUpdate: () => {
                this.$emit('update:value', this.editor.getHTML())
                this.$emit('change', this.editor.getHTML())
            },
            editorProps: {
                //attributes: {
                //    class: 'focus:outline-none prose mx-auto',
                //},
                handleDrop(view, event, slice, moved) {
                    const hasFiles =
                        event.dataTransfer &&
                        event.dataTransfer.files &&
                        event.dataTransfer.files.length

                    if (!hasFiles) {
                        return false
                    }

                    const images = Array.from(event.dataTransfer.files).filter(
                        (file) => /image/i.test(file.type)
                    )

                    if (images.length === 0) {
                        return false
                    }

                    event.preventDefault()

                    //images.forEach(async (imageFile) => {
                    //    const imageUrl = await uploadImage(imageFile)
                    //    this.editor.commands.setImage({
                    //        src: imageUrl,
                    //    })
                    //})
                    images.forEach(async (imageFile) => {
                        const reader = new FileReader()
                        reader.onload = (e) => {
                            const image = new Image()
                            image.onload = async () => {
                                const width = image.width
                                const height = image.height
                                const imageUrl = await uploadImage(imageFile)
                                editor.commands.setMedia({
                                    src: imageUrl,
                                    'media-type': 'img',
                                    title: imageFile.name,
                                    width,
                                    height,
                                })
                            }
                            image.src = e.target.result as string
                        }
                        reader.readAsDataURL(imageFile)
                    })

                    return true
                },
            },
        })

        //@ts-ignore
        this.editor = editor
    },

    beforeUnmount() {
        //@ts-ignore
        this.editor?.destroy()
    },
})
</script>

<style lang="scss">
.editor {
    display: flex;
    flex-direction: column;
    max-height: 26rem;
    color: #0d0d0d;
    background-color: #fff;
    border: 1px solid var(--Gray-Scale-Gray-300, #d6d9e0);

    &__header {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        flex-wrap: wrap;
        padding: 0.25rem;
        border-bottom: 1px solid var(--Gray-Scale-Gray-300, #d6d9e0);
    }

    &__content {
        padding: 1.25rem 1rem;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    &__footer {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        white-space: nowrap;
        border-top: 1px solid var(--Gray-Scale-Gray-300, #d6d9e0);
        font-size: 12px;
        font-weight: 600;
        color: #0d0d0d;
        white-space: nowrap;
        padding: 0.25rem 0.75rem;
    }

    /* Some information about the status */
    &__status {
        display: flex;
        align-items: center;
        border-radius: 5px;

        &::before {
            content: ' ';
            flex: 0 0 auto;
            display: inline-block;
            width: 0.5rem;
            height: 0.5rem;
            background: rgba(#0d0d0d, 0.5);
            border-radius: 50%;
            margin-right: 0.5rem;
        }

        &--connecting::before {
            background: #616161;
        }

        &--connected::before {
            background: #b9f18d;
        }
    }

    &__name {
        button {
            background: none;
            border: none;
            font: inherit;
            font-size: 12px;
            font-weight: 600;
            color: #0d0d0d;
            border-radius: 0.4rem;
            padding: 0.25rem 0.5rem;

            &:hover {
                color: #fff;
                background-color: #0d0d0d;
            }
        }
    }
}
</style>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0d0d0d;
        color: #fff;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    mark {
        background-color: #faf594;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    hr {
        margin: 1rem 0;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0d0d0d, 0.1);
        margin: 2rem 0;
    }

    ul[data-type='taskList'] {
        list-style: none;
        padding: 0;

        li {
            display: flex;
            align-items: center;

            > label {
                flex: 0 0 auto;
                margin-right: 0.5rem;
                user-select: none;
            }

            > div {
                flex: 1 1 auto;
            }
        }
    }
}

.ProseMirror-focused {
    outline: none;
}
</style>
