import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end mt-2" }

import { ref, onMounted, toRef, computed } from 'vue'
import { Codemirror } from 'vue-codemirror'
import { MySQL, sql } from '@codemirror/lang-sql'
import { linter, Diagnostic } from '@codemirror/lint'
import { EditorView } from '@codemirror/view'


export default /*@__PURE__*/_defineComponent({
  __name: 'SqlEditor',
  props: {
    name: {
        type: String,
    },
    value: {
        type: String,
        default: '',
    },
    height: {
        type: String,
        default: '200px',
    },
    width: {
        type: String,
        default: '100%',
    },
    readonly: {
        type: Boolean,
        default: false,
    },
},
  emits: ['update:value'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const code = toRef(props, 'value')

const editorOptions = {
    lineNumbers: true,
    tabSize: 2,
    indentWithTabs: true,
}

const extensions = computed(() => [
    sql({ dialect: MySQL }), // MySQL용 SQL 설정
    linter((view) => {
        const diagnostics: Diagnostic[] = []
        // 여기에 진단 로직을 추가하세요
        return diagnostics
    }),
    // 읽기 전용 모드로 설정
    EditorView.editable.of(!props.readonly),
])

const onChange = (value: string) => {
    code.value = value
    emits('update:value', code.value)
}

onMounted(() => {
    const editor = ref<any>(null)
})

return (_ctx: any,_cache: any) => {
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form_item, { name: __props.name }, {
      default: _withCtx(() => [
        _createVNode(_unref(Codemirror), {
          ref: "editor",
          modelValue: code.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((code).value = $event)),
          extensions: extensions.value,
          options: editorOptions,
          style: _normalizeStyle({
                    height: __props.height,
                    width: __props.width,
                }),
          onChange: onChange
        }, null, 8, ["modelValue", "extensions", "style"]),
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "button")
        ])
      ]),
      _: 3
    }, 8, ["name"])
  ]))
}
}

})