import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { formatEnum, formatLocalDate } from '@/util/formmater'
import { onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormeDietPhase',
  setup(__props) {

const columns = [
    {
        title: 'ID',
        dataIndex: 'tsid',
        fixed: 'left',
        width: 100,
    },
    {
        title: 'User ID',
        dataIndex: 'userId',
        width: 100,
    },
    {
        title: 'Membership ID',
        dataIndex: 'userMembershipId',
    },
    {
        title: 'Phase',
        dataIndex: 'phase',
        customRender: formatEnum('forme-diet-phase'),
    },
    {
        title: '시작일',
        dataIndex: 'startDate',
        customRender: formatLocalDate,
        width: 150,
    },
    {
        title: '종료일',
        dataIndex: 'endDate',
        customRender: formatLocalDate,
        width: 150,
    },
]

const filterSpec: FormSpecification = {
    userId: {
        label: 'User ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    phase: {
        label: 'Phase',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-diet-phase',
    },
}

const inputSpec: FormSpecification = {
    userId: {
        label: 'User ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    phase: {
        label: 'Phase',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-diet-phase',
    },
    startDate: {
        label: '시작일',
        value: null,
        component: InputComponents.ADatePicker,
    },
    endDate: {
        label: '종료일',
        value: null,
        component: InputComponents.ADatePicker,
    },
}

onMounted(() => {
    useEnumTypeStore().dispatchEnums(['forme-diet-phase'])
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ResourceTable, {
    title: "포미 Phase",
    "primary-key": "tsid",
    "resource-name": "forme-diet-phase",
    columns: columns,
    uri: "/api/forme/diet/phase",
    "load-on-mount": "",
    "filter-spec": filterSpec,
    "create-spec": inputSpec,
    "update-spec": inputSpec,
    deletable: ""
  }))
}
}

})