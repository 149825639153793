import dedent from 'dedent'

const WEEKLY_2_PROMPT = `
혈당 안정화가 잘 이루어진 경우의 예시:

오늘은 @@님의 지난 혈당 변화를 쭉 살펴봤는데요,
**전반적인 모든 수치가 개선**되어 혈당 그래프가 안정적으로 바뀌어 가는 게 느껴지더라구요!
짧은 시간에 이렇게 건강한 변화 만들기 쉽지 않으셨을텐데
'**혈당 안정화**' 과정을 잘 해나가고 계시다는 말씀 드리고 싶어요👍✨
오늘은 @@님의 지난 혈당 변화를 쭉 살펴봤는데요,
**최고혈당과 고혈당 노출 시간 등에** 긍정적인 변화가 있었더라구요!

🔹 혈당 스파이크 횟수 7회 → 5회
🔹최고 혈당 172mg/dL → 155mg/dL
🔹고혈당 노출시간 69분 → 55분

짧은 시간에 이렇게 건강한 변화 만들기 쉽지 않으셨을텐데
'**혈당 안정화**' 를 위한 과정을 잘 해나가고 계시다는 말씀 드리고 싶어요👍✨

---

혈당 안정화가 잘 이루어지지 않은 경우의 예시:

@@님의 혈당 변화를 쭉 살펴봤는데요,
초반에 비해 스파이크 횟수는 줄었으나

🔸 최근에 큰 혈당 스파이크가 발생하면서 혈당 변동성이 커지고
🔸 최고혈당이 높아져

식욕 조절에 대한 어려움을 느끼고 계신 건 아닌지 걱정되더라구요🥲
아직 '혈당 안정화'가 완벽히 진행되지 않은 상황이기 때문에
**🚨 지금 타이밍이 굉장히 중요해요!**
여기서 혈당 롤러코스터의 브레이크가 잘 잡히지 않으면
✔️ 배고픔이 더 자주 들고, 심하게 느껴지거나
✔️ 탄수화물, 달달한 음료 등이 당길 수 있거든요!
@@님이 느끼기에는 어떠세요?
프로그램 초반에 체중 관리를 어렵게 하는 셀프원인으로 **많이 먹는 것**을 말씀해주시기도 하셨어서 요즘 어떤 상황인지도 여쭤보고 싶어요 :)
`

const WEEKLY_3_PROMPT = `
예시1:

@@님께서 미션 실천을 위해 노력해주신 덕분에 '**혈당 안정화**'가 잘 이루어지고 있더라구요👍✨
덕분에 컨디션 변화도 이렇게 저렇게~~ 나타나고 있네요!
**초반에 체중관리를 어렵게 하는 셀프원인**으로

✔️ 과식과
✔️ 지속적으로 움직이지 않은 것을 말씀해주셨었는데,

어쩌구 저쩌구로 변화해가고 있어요!

예시2:

앞으로는 혈당 점수와 함께 **'식단 점수'도 메인 지표로 삼아 관리**를 이어나가보려고 해요!
필라이즈의 식단 점수는 크게 **[칼로리 균형]**과 **[탄단지 비율 균형]**에 초점을 맞추고 설정된 지표에요.
식단의 ‘조화와 균형’을 맞춰 나가는 것이 목표이고, 혈당 점수보다 고득점하기 다소 어려우실 수 있는데요.
지금까지 @@님이 쌓아온 식단 관리 데이터를 볼 때 무리 없이 목표 달성 가능하실 것 같아보여요!
'탄단지 비율'은 제가 @@님의 몸 상태에 맞게 조정해드렸으니 확인해주시구요.
여기에 맞춰 드셔보시면서 점차 @@님께 적합한 식사의 균형을 찾아나가는 단계라고 생각해주세요.
혈당센서가 남은 약 일주일간 혈당 점수와 식단 점수를 함께 살펴보면, 식단에 대한 종합적인 최적의 균형을 잡아가는데 도움이 될 거예요😊
식단 관리에 중요한 요소인 '목표 칼로리'와 '탄단지 비율'은 제가 @@님의 몸 상태에 맞게 조정해드렸으니 확인해주시구요.
여기에 맞춰 드셔보시면서 점차 @@**님께 적합한 식사의 균형을 찾아나가는 단계**라고 생각해주세요.
관리 상황을 꼼꼼히 지켜보면서 평생 유지해나갈 수 있는 식습관을 만들어 나갈 수 있도록 최적의 균형을 잡아가는 것에 집중해볼게요⭐
`

const WEEKLY_5_PROMPT = `
매일 적정 칼로리를 섭취했을때 예시:

데이터를 확인해보니

지난 주 **본격적인 칼로리 결핍**을 만들기 위해
⭐ 섭취 칼로리와 활동 칼로리의 비율을 맞춘 **클린데이**를 늘려보기로 약속했었죠!
💚 기존에 관리를 잘 해오셔서 그런지 무리 없이 **ALL 클린데이 만들기를 성공**하셨더라구요. 지금 습관 아주 좋으니 한 주 더 이어가며 나만의 습관으로 자리잡을 수 있도록 해볼게요!
이미 잘 하고 계시지만 **칼로리 결핍에 가속도**를 달고 지속 가능한 식사 및 운동 습관을 위한 미션을 추가로 드리려고 해요!

매일 적정 칼로리 섭취에 실패했을때 예시:

지난 주 **본격적인 칼로리 결핍**을 만들기 위해
⭐ 섭취 칼로리와 활동 칼로리의 비율을 맞춘 **클린데이**를 늘려보기로 약속했었죠!
🟨 대체로 클린데이를 유지해주셨지만 주 n회의 치팅데이가 발생했더라구요ㅠㅠ 이 목표는 한 주 더 이어가며 아쉬웠던 점을 보완하는 시간 가져볼게요!
그리고 칼로리 결핍을 만드는 데 있어 어려움이 있으셨던 것 같아 도움이 될 수 있는 식사 및 운동 습관 관리 미션을 추가로 드릴게요!

참고:

클린데이는 대상 날짜중 모든날에 섭취 칼로리가 목표칼로리를 초과하지 않음을 뜻한다.
섭취칼로리가 0이거나 없는 날은 미입력임으로 미입력 수치가 있다면 ALL 클린데이가 아니다.
`

export const weeklyPreset = [
    {
        week: 5,
        prompt: WEEKLY_5_PROMPT,
    },
]

export const promptPreset = [
    {
        label: '주말',
        value: `저번 주말 다이어트 관리상태에 대해 피드백해주고 이번주도 잘 할 수 있도록 피드백해줘. 데이터는 정확하게 봐야해.`,
    },
    {
        label: '어제',
        value: `어제의 다이어트 관리상태에 대해 피드백해주고 오늘도 잘 할 수 있도록 피드백해줘. 데이터는 정확하게 봐야해.`,
    },
    {
        label: '음식 팁',
        value: dedent(`
1. 식단 데이터를 참고하여 음식 팁을 제공한다.
2. 음식 팁에 없는 음식은 아래 가이드라인을 참고하여 팁을 제공한다.
    가이드라인:
    - 거꾸로 식사 소개
        - 해당 식사에서 식이섬유 함량이 가장 높았던 음식을 가장 먼저 먹고 (김치, 현미밥 제외, 채소류만 골라줄 수 있는지 궁금)
        - 해당 식사에서 단백질 함량이 가장 높았던 음식을 그 다음으로 먹고
        - 해당 식사에서 탄수화물 함량이 가장 높은 음식을 가장 마지막에 소량만, 맛만 본다는 느낌으로 먹으라는 내용의 가이드 제공
        - 거꾸로 식사 예시:
            👉 식이섬유 보충을 위해 아스파라거스, 버섯, 파프리카 같은 채소를 먼저 충분히 드셔보세요.
            👉 그 다음 크림새우를 드시고
            👉 마지막에 감자스프 순으로 드시면 혈당 반응이 달라질 거예요!

    - 식후 활동
        - 식후 운동 기록 없으면 → 운동 실천 후 혈당 반응 보라고 안내.
            - 예시: ▶️ 기록상으로 운동 확인이 어려운데요, 안정적인 혈당 조절을 위해 식후 15분~30분 내 걷기, 스쿼트, 계단오르기 등의 활동을 챙겼다면 혈당 변화가 달라졌을 거예요!
        - 식후 운동 기록이 있는데 이후 혈당이 상승했으면 → 운동 시간을 당기고 강도를 높여보라 안내
            - 예시:
                **▶️** 혈당 조절을 위해 적절한 운동 타이밍은 식후 30분~1시간 이내를 권장해요. 가능하다면 활동 시간을 앞당겨볼 것을 추천해요.
                **▶️** 식후 운동을 해주신 것은 좋았는데, 2시간 이후에 하셔서 타이밍이 살짝 아쉬웠던 것 같아요🥲
                타이밍은 늘 강조드리는 부분이기는 하나, 이 경우 30분 이내 걷기, 계단오르기, 스쿼트 등을 실천하셨다면 혈당 변화가 달라졌을 거예요 :)
        - 식후 운동 기록이 있고 이후 혈당이 하강했으면 → 앞으로도 계속 하라고 칭찬
            - 예시:
                ▶️ 식후 걷기와 이후 추가 운동까지 운동 타이밍이 아주 좋았어요!
                덕분에 식후 혈당 조절이 비교적 잘 되었어요.
                (식후 운동의 중요성을 다시 한 번 느끼지 않으셨을까 싶어요😉)

    - 탄수화물 섭취량 줄이기
        식사중 탄수화물이 가장 높은 음식에 대하여 혈당이 높은 이유를 설명하고
        혈당을 낮추면서 먹을수 있는 방법을 소개한다.

3. 문장의 표현이나 어휘들은 다른 팁을 참고하여 유지한다.
        `),
    },
]
