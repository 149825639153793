<style lang="scss">
.wm-container {
    display: flex;

    .wm-form-item {
        display: flex;
    }

    .wm-btn-save {
        margin-right: 10px;
    }

    .wm-btn-assign {
        margin-right: 50px;
    }

    .wm-assign-message {
        margin-top: 10px;
    }

    .ant-form-item-control-input-content {
        display: flex;
    }
}

.tbg-container,
.meal-container {
    display: flex;
    align-items: center;
}

.ic-edit {
    display: flex;
    width: 16px;
    height: 16px;
    margin-left: 2px;

    &:hover {
        cursor: pointer;
    }
}

.tbg-input {
    display: flex;
    width: 48px;
    height: 32px;
    align-items: center;
    justify-content: center;

    .ant-input-number-input {
        text-align: center;
    }
}

.tbg-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
    height: 32px;
    padding: 10px;
}

.modal-meal-info {
    left: 0;
    right: 0;

    .modal-content-container {
        display: flex;
        align-items: center;

        &.content-cpf {
            align-items: flex-start;

            .modal-input {
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }

        .modal-content-section {
            display: flex;
            flex-direction: column;
        }
    }

    .modal-input {
        width: 100px;
        display: flex;
        justify-content: center;

        .ant-input-number-input {
            display: flex;
            padding: 0;
            text-align: center;
        }

        &.plain-text {
            color: #787e8b;
            text-align: center;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
        }
    }
}
</style>
<script setup lang="tsx">
import { computed, defineProps, onMounted, ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import WeekSelectInput from '@/components/input/WeekSelectInput.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { getDateList } from '@/util/date-util'
import NestedResourceTable from '@/components/NestedResourceTable.vue'
import {
    formatKoreanTimeOnlyTime,
    formatLocalDate,
    formatLocalDateTime,
} from '@/util/formmater'
import { differenceInDays, format, isBefore } from 'date-fns'
import { ko } from 'date-fns/locale'
import { Button, MenuProps, message, Tag } from 'ant-design-vue'
import {
    EyeInvisibleOutlined,
    EyeOutlined,
    LinkOutlined,
    PlusOutlined,
    SendOutlined,
} from '@ant-design/icons-vue'
import globalFormState from '@/components/globalFormState'
import { required } from '@/util/input-validation'
import { stringify } from 'qs'
import { debounce } from 'lodash'
import Draggable from 'vuedraggable'
import Tiptap from '@/components/input/tiptap/Tiptap.vue'
import { useFormeWeekStore } from '@/store/formeWeek'
import { useEnumTypeStore } from '@/store/enumType'
import useChatApp from '@/components/chat/useChatApp'
import EnumSelect from '@/components/input/EnumSelect.vue'

const props = defineProps({
    userId: {
        type: Number,
        required: true,
    },
})

const filterParams = ref<Record<string, any>>({
    weekNumber: 1,
})

const loading = ref(false)
const findFactorFail = ref(false)
const table = ref<any>(null)
const dataTable = ref<any>(null)
const conditionSurvey = ref<any[]>([])

const hideWeeklyMission = async (id: string) => {
    loading.value = true
    await adminApi(`/api/forme/diet/mission/hide/${props.userId}/${id}`, {
        method: 'POST',
    })
    await fetchTable()
    loading.value = false
}

const getConditionSurvey = async () => {
    loading.value = true
    const response1 = await adminApi(
        `/api/forme/diet/survey/${props.userId}/1`,
        {
            method: 'GET',
        }
    )
    await fetchTable()

    if (response1.result.length > 0) {
        conditionSurvey.value[0] = response1.result || {}
    }

    loading.value = true
    const response2 = await adminApi(
        `/api/forme/diet/survey/${props.userId}/2`,
        {
            method: 'GET',
        }
    )
    await fetchTable()
    if (response2.result.length > 0) {
        conditionSurvey.value[1] = response2.result || {}
    }

    loading.value = true
    const response3 = await adminApi(
        `/api/forme/diet/survey/${props.userId}/3`,
        {
            method: 'GET',
        }
    )
    await fetchTable()

    if (response3.result.length > 0) {
        conditionSurvey.value[2] = response3.result || {}
    }
    loading.value = false
}

const { formeWeek, getWeekList, getLastDate } = useFormeWeekStore()

const columns = computed(() => {
    const selectedWeek = formeWeek.selectedWeek
    const dateTileList =
        selectedWeek != null
            ? getDateList(selectedWeek.startDate, selectedWeek.endDate).map(
                  (it, index) => ({
                      title: format(it, 'M.d(EEE)', { locale: ko }),
                      dataIndex: `day${index + 1}`,
                      align: 'center',
                      customRender: (column: any) => {
                          if (column.value) {
                              return '✅'
                          } else {
                              return ''
                          }
                      },
                  })
              )
            : []
    return [
        {
            title: 'ID',
            dataIndex: 'userMissionId',
            width: 80,
            fixed: 'left',
        },
        {
            title: '타입',
            dataIndex: 'type',
        },
        {
            title: '종류',
            dataIndex: 'category',
        },
        {
            title: '미션',
            dataIndex: 'name',
            customRender: ({
                value,
                record,
            }: {
                value: any
                record: Record<string, any>
            }) => {
                if (value.includes('{변수}')) {
                    return value.replace('{변수}', record.value)
                }
                return value
            },
            customCell: (record: Record<any, any>) => {
                if (record.feedbackScore === 1) {
                    return {
                        style: {
                            backgroundColor: '#FFF9DE',
                        },
                    }
                } else if (record.feedbackScore === 5) {
                    return {
                        style: {
                            backgroundColor: '#FFEFF2',
                        },
                    }
                }
            },
        },
        {
            title: '가중치 점수',
            dataIndex: 'weight',
        },
        ...dateTileList,
        {
            title: '달성률',
            dataIndex: 'completeRatio',
            align: 'center',
            customRender: ({ value, record }: any) => {
                return `${value}%`
            },
            customCell: (record: any) => {
                const ratio = record.completeRatio
                if (ratio >= 85) {
                    return {
                        style: {
                            backgroundColor: '#efffff',
                        },
                    }
                } else if (ratio >= 70 && ratio < 85) {
                    return {
                        style: {
                            backgroundColor: '#effff4',
                        },
                    }
                } else if (ratio >= 55 && ratio < 70) {
                    return {
                        style: {
                            backgroundColor: '#fff9de',
                        },
                    }
                }
                return {
                    style: {
                        backgroundColor: '#ffeff2',
                    },
                }
            },
        },
        {
            title: '수정 날짜',
            dataIndex: 'updatedAt',
            customRender: formatLocalDate,
        },
        {
            title: '상태',
            dataIndex: 'hidden',
            customRender: ({
                value,
                record,
            }: {
                value: any
                record: Record<any, any>
            }) => {
                return value ? (
                    <Button
                        style="background-color: #9ba1aa; color: white"
                        loading={loading.value}
                        size="middle"
                        onClick={async () =>
                            await hideWeeklyMission(record.userMissionId)
                        }
                    >
                        {loading.value == false && <EyeInvisibleOutlined />}
                    </Button>
                ) : (
                    <Button loading={loading.value} size="middle">
                        <EyeOutlined
                            onClick={async () =>
                                await hideWeeklyMission(
                                    record.userMissionId
                                ).then()
                            }
                        />
                    </Button>
                )
            },
            align: 'center',
        },
        {
            title: '수정',
            type: 'edit',
            align: 'center',
            fixed: 'right',
        },
    ]
})

const monitorColumnNames: Record<string, any> = {
    weight: {
        name: '몸무게',
        format: (value: number) => {
            if (value == null || value == 0) {
                return ''
            }
            return `${Number(value).toFixed(1)}kg`
        },
    },
    height: {
        name: '키',
        format: (value: number) => `${Number(value).toFixed(1)}cm`,
    },
    pbf: {
        name: '체지방률',
        format: (value: number) => `${Number(value).toFixed(1)}%`,
    },
    smm: {
        name: '골격근량',
        format: (value: number) => `${Number(value).toFixed(1)}kg`,
    },
    glucoseScore: {
        name: '혈당점수',
        format: (value: number) => `${value}점`,
    },
    maxGlucoseLevel: {
        name: '최고혈당',
        format: (value: number) => `${Number(value).toFixed(0)}mg/dL`,
    },
    spikeCount: {
        name: '혈당스파이크횟수',
        format: (value: any) => `${value}회`,
    },
    highBloodGlucoseDuration: {
        name: '고혈당노출시간',
        format: (value: any) => `${value}분`,
    },
    lowBloodGlucoseDuration: {
        name: '저혈당노출시간',
        format: (value: any) => `${value}분`,
    },
    avgGlucoseLevel: {
        name: '평균혈당',
        format: (value: number) => `${Number(value).toFixed(0)}mg/dL`,
    },
    fbg: {
        name: '공복혈당',
        format: (value: number) => `${Number(value).toFixed(0)}mg/dL`,
    },
    cvGlucoseLevel: {
        name: '혈당변동성',
        format: (value: number) => `${Number(value).toFixed(0)}%`,
    },
    healthyLevelRatio: {
        name: '건강혈당범위',
        format: (value: any) => `${Number(value).toFixed(0)}%`,
    },
    dietScore: {
        name: '식단점수',
        format: (value: any) => `${value}점`,
    },
    intakeCalorie: {
        name: '섭취칼로리(kcal)',
        format: (value: any) => `${value}kcal`,
    },
    intakeCalorieRatio: {
        name: '섭취칼로리(%)',
        format: (value: any) => `${Number(value).toFixed(0)}%`,
    },
    carbohydrateAmount: {
        name: '탄수화물(g)',
        format: (value: any) => `${Number(value).toFixed(0)}g`,
    },
    carbohydrateRatio: {
        name: '탄수화물(%)',
        format: (value: any) => `${Number(value).toFixed(0)}%`,
    },
    proteinAmount: {
        name: '단백질(g)',
        format: (value: any) => `${Number(value).toFixed(0)}g`,
    },
    proteinRatio: {
        name: '단백질(%)',
        format: (value: any) => `${Number(value).toFixed(0)}%`,
    },
    fatAmount: {
        name: '지방(g)',
        format: (value: any) => `${Number(value).toFixed(0)}g`,
    },
    fatRatio: {
        name: '지방(%)',
        format: (value: any) => `${Number(value).toFixed(0)}%`,
    },
    macronutrientRatio: {
        name: '탄단지 비율',
        format: (value: any) => `${value}`,
    },
    sugarAmount: {
        name: '당류(g)',
        format: (value: any) => `${Number(value).toFixed(0)}g`,
    },
    alcoholAmount: {
        name: '알코올(g)',
        format: (value: any) => `${Number(value).toFixed(0)}g`,
    },
    caffeineAmount: {
        name: '카페인(mg)',
        format: (value: any) => `${Number(value).toFixed(0)}mg`,
    },
    intakeCount: {
        name: '끼니수',
        format: (value: any) => `${value}회`,
    },
    activeCalorie: {
        name: '활동칼로리(kcal)',
        format: (value: any) => `${value}kcal`,
    },
    workoutList: {
        name: '운동 종류',
        format: (value: string) => {
            const workoutList = [...new Set(JSON.parse(value))]
            return (
                <span>
                    {workoutList.map((it: any) => (
                        <Tag>{it}</Tag>
                    ))}
                </span>
            )
        },
        //JSON.parse(value).map((it: string) => <Tag>{it}</Tag>),
    },
    zone1Minute: {
        name: 'zone1 운동시간',
        format: (value: any) => `${value}분`,
    },
    zone2Minute: {
        name: 'zone2 운동시간',
        format: (value: any) => `${value}분`,
    },
    zone3Minute: {
        name: 'zone3 운동시간',
        format: (value: any) => `${value}분`,
    },
    zone4Minute: {
        name: 'zone4 운동시간',
        format: (value: any) => `${value}분`,
    },
    zone5Minute: {
        name: 'zone5 운동시간',
        format: (value: any) => `${value}분`,
    },
    goeZone2Minute: {
        name: 'zone2 이상 운동시간',
        format: (value: any) => `${value}분`,
    },
    fastingTime: {
        name: '단식시간',
        format: (value: any) => `${Number(value / 3600).toFixed(0)}시간`,
    },
    fastingCompleteRate: {
        name: '단식달성률',
        format: (value: any) => `${value}%`,
    },
    firstIntakeAt: {
        name: '첫 끼니시간',
        format: formatKoreanTimeOnlyTime,
    },
    lastIntakeAt: {
        name: '마지막 끼니시간',
        format: formatKoreanTimeOnlyTime,
    },
    workoutTotalTime: {
        name: '총 운동 시간',
        format: (value: any) =>
            `${Number(Math.floor(value / 60)).toFixed(0)}분`,
    },
    dailySteps: {
        name: '걸음수',
        format: (value: any) => `${value}걸음`,
    },
    workoutStartAt: {
        name: '마지막 운동 시작 시간',
        format: formatLocalDateTime,
    },
    sleepMinute: {
        name: '수면지속시간',
        format: (value: any) => `${Number(value / 60).toFixed(0)}시간`,
    },
    sleepStartAt: {
        name: '취침 시간',
        format: formatLocalDateTime,
    },
    targetDate: {
        name: '대상날짜',
        format: formatLocalDate,
    },
}

const colorPalate: Record<any, string> = {
    blue: '#EFFFFF',
    green: '#EFFFF4',
    yellow: '#FFF9DE',
    red: '#FFEFF2',
}

const monitorColumns = computed(() => {
    const selectedWeek = formeWeek.selectedWeek
    const dateTitleList =
        selectedWeek != null
            ? getDateList(selectedWeek.startDate, selectedWeek.endDate).map(
                  (it, index) => ({
                      title: format(it, 'M.d(EEE)', { locale: ko }),
                      dataIndex: `day${index + 1}`,
                      align: 'center',
                      customRender: ({
                          value,
                          record,
                      }: {
                          value: any
                          record: Record<any, any>
                      }) => {
                          if (
                              value &&
                              monitorColumnNames[record.property] != null &&
                              monitorColumnNames[record.property].format
                          ) {
                              return monitorColumnNames[
                                  record.property
                              ]?.format(value)
                          }
                      },
                      customCell: (record: Record<any, string>) => {
                          if (record[`day${index + 1}Color`]) {
                              return {
                                  style: {
                                      backgroundColor:
                                          colorPalate[
                                              record[`day${index + 1}Color`]
                                          ],
                                  },
                              }
                          }
                      },
                  })
              )
            : []

    return [
        {
            title: '',
            dataIndex: 'property',
            customRender: (column: { value: string }) => {
                return monitorColumnNames[column.value]?.name
            },
        },
        ...dateTitleList,
        {
            title: '실패 일수',
            dataIndex: 'failCount',
        },
        {
            title: '주간 데이터',
            dataIndex: 'weekValue',
        },
    ]
})

const userDetail = ref<any>({})
const setUserDetail = async (userId: number) => {
    const response = await adminApi<ApiResponse<any>>(
        `/api/forme/diet/user/${userId}?weekNumber=${
            filterParams.value.weekNumber || 1
        }`
    )
    userDetail.value = response.result || {}
}
const updateDietFactorType = async () => {
    try {
        await adminApi(`/api/forme/diet/diet-factor/${props.userId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                dietFactorTypeList: userDetail.value.dietFactorTypeList.map(
                    (it: any) => it.dietFactorType
                ),
            }),
        })
    } catch (e) {
        message.error('다이어트 요소를 수정하는데 실패했습니다.')
    } finally {
        await setUserDetail(props.userId)
    }
}

const searchInputVisible = ref(false)
const dietFactorTypeOptions = ref<any[]>([])
const selectDietFactorType = async (dietFactorType: any) => {
    const selectedOption = dietFactorTypeOptions.value.find(
        (it) => it.value === dietFactorType
    )
    userDetail.value.dietFactorTypeList.push({
        ...selectedOption,
    })
    await updateDietFactorType()
    searchInputVisible.value = false
}
const blurInput = () => {
    searchInputVisible.value = false
}
const searchDietFactorType = async (keyword: string) => {
    if (keyword != null && keyword.length < 2) {
        return
    }
    try {
        const response = await adminApi<ApiResponse<any>>(
            `/api/forme/diet/diet-factor/${keyword}`
        )
        const result = response.result || []
        dietFactorTypeOptions.value = result.map((it: any) => ({
            ...it,
            label: `${it.category} ${it.reason}`,
            value: it.dietFactorType,
        }))
    } catch {
        message.error('다이어트 요소를 불러오는데 실패했습니다.')
    }
}

const showInput = () => {
    searchInputVisible.value = true
}

const handleDietFactorClose = async (dietFactorType: string) => {
    userDetail.value.dietFactorTypeList =
        userDetail.value.dietFactorTypeList.filter(
            (it: any) => it.dietFactorType !== dietFactorType
        )
    await updateDietFactorType()
}

const mainWm = ref()
const buddyWm = ref()
const subWm = ref([])

const hasAssignMessage = ref(false)
const assignMessageSendTime = ref<any>(null)

const initWellnessMaster = async (userId: number) => {
    const params = {
        userId: userId,
    }
    const response = await adminApi<ApiResponse<any>>(
        `/api/forme/diet/v1/wm-info?${stringify(params)}`
    )
    const mainWmResponse = response?.result?.mainWmList || []
    const mainWmList = mainWmResponse.map((wm: any) => {
        return {
            label: wm.wellnessMasterName,
            value: wm.wellnessMasterId,
        }
    })

    const buddyWmResponse = response?.result?.buddyWmList || []
    const buddyWmList = buddyWmResponse.map((wm: any) => {
        return {
            label: wm.wellnessMasterName,
            value: wm.wellnessMasterId,
        }
    })

    const subWmResponse = response?.result?.subWmList || []
    const subWmList = subWmResponse.map((wm: any) => {
        return {
            label: wm.wellnessMasterName,
            value: wm.wellnessMasterId,
        }
    })

    mainWm.value = mainWmList[0]
    buddyWm.value = buddyWmList[0]
    subWm.value = subWmList

    hasAssignMessage.value = response?.result?.hasAssignMessage
    assignMessageSendTime.value = response?.result?.assignMessageSendTime
}

const { unreadCount, fetchUnreadCount, fetchQnaEnable, writeMessage } =
    useChatApp

const loadingPage = ref(false)

onMounted(async () => {
    loadingPage.value = true
    await getWeekList(props.userId)
    getWeeklyMessage(props.userId, filterParams.value.weekNumber || 1)
    filterParams.value.weekNumber = formeWeek.selectedWeek.weekNumber
    await setUserDetail(props.userId)
    await initWellnessMaster(props.userId)
    await getConditionSurvey()
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums([
        'forme-category',
        'forme-wellness-master',
        'diet-name',
    ])
    loadingPage.value = false
    await fetchData()
    await fetchQnaEnable(props.userId)
    await fetchUnreadCount(props.userId)
})

const { setFormValues, form, fetchFormSpec } = globalFormState

const rules = {
    priority: [required],
    category: [required],
    type: [required],
    startDate: [required],
    endDate: [required],
    weight: [required],
    name: [required],
}

const selectMissionId = (value: any) => {
    setFormValues({
        category: value.category,
        type: value.type,
        name: value.name,
        nameTemplate: value.name,
        value: value.variable,
        priority: value.priority,
        hide: value.hide,
    })
}

const createSpec = ref<FormSpecification>({
    missionId: {
        label: '미션 ID',
        component: InputComponents.FormeMissionInput,
        select: selectMissionId,
    },
    userMissionId: {
        label: '유저 미션 ID',
        readonly: true,
    },
    category: {
        label: '카테고리(혈당/식단/수면 등)',
        component: InputComponents.EnumSelect,
        typeName: 'forme-category',
    },
    type: {
        label: '미션 타입(AI타입/커스텀)',
        value: '커스텀',
    },
    priority: {
        label: '우선순위',
        component: InputComponents.AInputNumber,
    },
    name: {
        label: '미션 이름(치환할 값은 {변수}로)',
    },
    nameTemplate: {
        hidden: true,
    },
    value: {
        label: '변수값',
    },
    weight: {
        label: '가중치 점수',
        component: InputComponents.AInputNumber,
        value: 0,
    },
    startDate: {
        label: '미션 시작',
        component: InputComponents.ADatePicker,
        input: (value: any) => {
            if (value && form.value.endDate) {
                const diff = differenceInDays(form.value.endDate, value)
                setFormValues({
                    duration: diff,
                })
            }
        },
        value: formeWeek.selectedWeek.startDate,
    },
    endDate: {
        label: '미션 종료',
        component: InputComponents.ADatePicker,
        input: (value) => {
            if (value && form.value.startDate) {
                const diff = differenceInDays(value, form.value.startDate)
                setFormValues({
                    duration: diff,
                })
            }
        },
        value: getLastDate(),
    },
    duration: {
        label: '미션 기간',
        component: InputComponents.AInputNumber,
        value: differenceInDays(
            formeWeek.selectedWeek.endDate,
            formeWeek.selectedWeek.startDate
        ),
    },
    guide: {
        label: '미션 가이드',
        component: InputComponents.Tiptap,
    },
    hide: {
        label: '숨김 여부',
        component: InputComponents.BooleanInput,
    },
})

const updateSpec = ref<FormSpecification>({
    missionId: {
        label: '미션 ID',
        component: InputComponents.FormeMissionInput,
        select: selectMissionId,
    },
    userMissionId: {
        label: '유저 미션 ID',
        readonly: true,
    },
    category: {
        label: '카테고리(혈당/식단/수면 등)',
        component: InputComponents.EnumSelect,
        typeName: 'forme-category',
    },
    type: {
        label: '미션 타입(AI타입/커스텀(?))',
    },
    priority: {
        label: '우선순위',
        component: InputComponents.AInputNumber,
    },
    name: {
        label: '미션 이름',
        disabled: true,
    },
    nameTemplate: {
        hidden: true,
    },
    value: {
        label: '변수값',
    },
    weight: {
        label: '가중치 점수',
        component: InputComponents.AInputNumber,
        disabled: true,
    },
    startDate: {
        label: '미션 시작',
        component: InputComponents.ADatePicker,
        input: (value: any) => {
            if (value && form.value.endDate) {
                const diff = differenceInDays(form.value.endDate, value)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    endDate: {
        label: '미션 종료',
        component: InputComponents.ADatePicker,
        input: (value) => {
            if (value && form.value.startDate) {
                const diff = differenceInDays(value, form.value.startDate)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    duration: {
        label: '미션 기간',
        component: InputComponents.AInputNumber,
    },
    membershipEndDate: {
        label: '멤버십 종료 날짜',
        component: InputComponents.ADatePicker,
        readonly: true,
    },
    weekNumber: {
        label: '주차',
        component: InputComponents.WeekSelectInput,
        userId: props.userId,
        input: (value: any, resourceId: any) => {
            fetchFormSpec(
                `/api/forme/diet/mission/${props.userId}/${resourceId}?weekNumber=${value}`
            )
        },
    },
    guide: {
        label: '미션 가이드',
        component: InputComponents.Tiptap,
        disabled: () => isBefore(new Date(), formeWeek.selectedWeek.startDate),
        help: '작성한 가이드는 오늘 날짜 기준 사용자의 주차 미션에 적용됩니다.(주차를 따로 설정해도 적용되지 않습니다)',
    },
    feedbackScore: {
        hidden: true,
    },
    feedback: {
        label: '유저 미션 체크',
        readonly: true,
        type: InputType.PILLZ,
    },
    feedbackAnswer: {
        label: 'WM 미션 피드백',
        component: InputComponents.Tiptap,
        hidden: (record: Record<string, any>) => record.feedbackScore == null,
    },
    hide: {
        label: '숨김 여부',
        component: InputComponents.BooleanInput,
    },
})

const writeWmNote = debounce(async (userId: number, wmNote: string) => {
    await adminApi(`/api/forme/diet/wm/note/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            note: wmNote,
        }),
    })
    message.success('WM 메모가 성공적으로 수정되었습니다.')
}, 500)

const onWriteWmNote = (value: string) => {
    userDetail.value.wmMemo = value
    writeWmNote(props.userId, value)
}

const writeNotionLink = debounce(async (userId: number, notionLink: string) => {
    await adminApi(`/api/forme/diet/wm/notion/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            notion: notionLink,
        }),
    })
    message.success('노션 링크가 성공적으로 수정되었습니다.')
}, 500)

const onWriteNotionLink = (value: string) => {
    userDetail.value.notionLink = value
    writeNotionLink(props.userId, value)
}

const weeklyMessage = ref('')

const getWeeklyMessage = async (userId: number, weekNumber: number) => {
    const params = {
        weekNumber: weekNumber,
        formeMessageType: 'WEEKLY',
    }
    const response = await adminApi<ApiResponse<string>>(
        `/api/forme/diet/message/${userId}?${stringify(params)}`
    )
    weeklyMessage.value = response.result || ''
}

const fetchData = async () => {
    await setUserDetail(props.userId)
    fetchTable()
    getWeeklyMessage(props.userId, filterParams.value.weekNumber || 1)
}

const onUpdateCategory = async (value: any) => {
    filterParams.value.category = value
    fetchTable()
}

const selectedTable = ref<string[]>(['mission'])

const fetchTable = async () => {
    if (selectedTable.value.includes('mission')) {
        await table.value.fetchData()
    } else {
        await dataTable.value.fetchData()
    }
}

const reoderDietFactorTypeList = async (dietFactorTypeList: any[]) => {
    await adminApi(`/api/forme/diet/reorder/find-factor/${props.userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dietFactorTypeList),
    })
}

const onReorderDietFactorTypeList = async () => {
    await reoderDietFactorTypeList(
        userDetail.value.dietFactorTypeList.map((it: any) => it.dietFactorType)
    )
}

const completeMission = async () => {
    loading.value = true
    await adminApi(
        `/api/forme/diet/mission/complete/${props.userId}?weekNumber=${filterParams.value.weekNumber}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    loading.value = false
}

const createMissionByDietFactor = async () => {
    loading.value = true
    try {
        await adminApi(
            `/api/forme/diet/mission-by-diet-factor/${props.userId}`,
            {
                method: 'POST',
            }
        )
        await setUserDetail(props.userId)
    } catch (e) {
        console.error(e)
        findFactorFail.value = true
    } finally {
        loading.value = false
    }
}

const completeFindFactor = async () => {
    loading.value = true
    await adminApi(`/api/forme/diet/complete/find-factor/${props.userId}`, {
        method: 'POST',
    })
    setUserDetail(props.userId)
    loading.value = false
}

const isFuture = computed(() =>
    isBefore(new Date(), formeWeek.selectedWeek.startDate || new Date())
)

const collapseKey = ref<Array<number>>([])

const saveUserMasters = async () => {
    const url = `/api/forme/diet/v1/wm-assign`
    const parsedMainWmId = mainWm.value ? parseMainWmId(mainWm.value) : null
    const parsedBuddyWmId = buddyWm.value ? parseMainWmId(buddyWm.value) : null
    const parsedSubWmId = parseSubWmId(subWm.value)
    const payload = {
        userId: props.userId,
        mainWmIdList: [parsedMainWmId],
        buddyWmIdList: parsedBuddyWmId ? [parsedBuddyWmId] : [],
        subWmIdList: parsedSubWmId,
    }
    await executePostAPI(url, payload)
}

const parseMainWmId = (value: any) => {
    if (Object.hasOwn(value, 'value')) {
        return value.value
    }
    return value
}

const parseSubWmId = (value: any) => {
    if (value?.length > 0) {
        const obj = value[0]
        if (Object.hasOwn(obj, 'value')) {
            return value.map((v: any) => v.value)
        }
        return value
    }
    return []
}

const sendWMAssignMessage = async () => {
    const url = `/api/forme/diet/v1/wm-assign-message`
    const payload = {
        userId: props.userId,
    }
    await executePostAPI(url, payload)
    hasAssignMessage.value = true
    assignMessageSendTime.value = getCurrentTime()
}

const toggleSatisfactionSurvey = async (surveyNumber: number) => {
    const url = `/api/forme/diet/membership/toggle-survey`
    const payload = {
        userId: props.userId,
        surveyNumber: surveyNumber,
    }
    await executePostAPI(url, payload)
}

const executePostAPI = async (url: string, payload: any) => {
    loading.value = true
    await adminApi(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
    loading.value = false
}

function getCurrentTime() {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

const editableTbg = ref(false)
const editableNickName = ref(false)
const changeNickName = (value: string) => {
    userDetail.value.nickName = value
}
const changeTbgMin = (value: any) => {
    userDetail.value.tbgMin = value
}
const changeTbgMax = (value: any) => {
    userDetail.value.tbgMax = value
}
const saveTbg = async (e: any) => {
    e.preventDefault()
    const url = '/api/forme/diet/v1/tbg'
    const payload = {
        userId: userDetail.value.userId,
        tbgMin: userDetail.value.tbgMin,
        tbgMax: userDetail.value.tbgMax,
    }
    await executePostAPI(url, payload)
    editTbg()
}
const saveNickName = async (e: any) => {
    e.preventDefault()
    console.log(userDetail.value)
    const url = '/api/forme/diet/v1/nickName'
    const payload = {
        userId: userDetail.value.userId,
        nickName: userDetail.value.nickName,
    }
    await executePostAPI(url, payload)
    editNickName()
}
const editTbg = () => {
    editableTbg.value = !editableTbg.value
}
const editNickName = () => {
    editableNickName.value = !editableNickName.value
}

const cpfEditable = ref(false)
const carbohydrateGram = ref(0)
const proteinGram = ref(0)
const proteinWeightNx = ref(0)
const fatGram = ref(0)
const selectDiet = (value: any) => {
    userDetail.value.selectedDiet = value
}
const calCpfValues = () => {
    carbohydrateGram.value = calGram(userDetail.value.targetCarbohydrate, 4)
    proteinGram.value = calGram(userDetail.value.targetProtein, 4)
    proteinWeightNx.value = calWeightNx(userDetail.value.targetProtein)
    fatGram.value = calGram(userDetail.value.targetFat, 9)
}
const openMealModal = () => {
    calCpfValues()
    cpfEditable.value = true
}
const changeCalorie = (value: any) => {
    userDetail.value.targetCal = value
    calCpfValues()
}
const changeCarbohydrate = (value: any) => {
    userDetail.value.targetCarbohydrate = value
    carbohydrateGram.value = calGram(value, 4)
}
const changeProtein = (value: any) => {
    userDetail.value.targetProtein = value
    proteinGram.value = calGram(value, 4)
    proteinWeightNx.value = calWeightNx(value)
}
const changeFat = (value: any) => {
    userDetail.value.targetFat = value
    fatGram.value = calGram(value, 9)
}
const calGram = (targetValue: number, value: number) => {
    const rate = targetValue / 100
    return Math.round((userDetail.value.targetCal * rate) / value)
}
const calWeightNx = (value: number) => {
    const gram = calGram(value, 4)
    return (gram / userDetail.value.weight).toFixed(2)
}
const saveCpf = async () => {
    if (checkCpf()) {
        alert('탄단지 합계는 100 이어야 합니다.')
        return
    }
    const url = '/api/forme/diet/v1/cpf'
    const payload = {
        userId: userDetail.value.userId,
        selectedDiet: userDetail.value.selectedDiet,
        calorie: userDetail.value.targetCal,
        carbohydrate: userDetail.value.targetCarbohydrate,
        protein: userDetail.value.targetProtein,
        fat: userDetail.value.targetFat,
    }
    await executePostAPI(url, payload)
    cpfEditable.value = false
}
const checkCpf = () => {
    const sum =
        userDetail.value.targetCarbohydrate +
        userDetail.value.targetProtein +
        userDetail.value.targetFat
    return sum != 100
}

const tableMenu = ref<MenuProps['items']>([
    {
        key: 'mission',
        label: '미션',
        title: '미션',
    },
    {
        key: 'data',
        label: '데이터',
        title: '데이터',
    },
    {
        key: 'findFactor',
        label: '범인 찾기',
        title: '범인 찾기',
    },
])

const writeWeeklyMessage = async (userId: number) => {
    loading.value = true
    await writeMessage(userId, 'WEEKLY', 'HTML', weeklyMessage.value)
    await setUserDetail(userId)
    loading.value = false
}

const findFactorReportColumns = computed(() => {
    const selectedWeek = formeWeek.selectedWeek
    const dateTileList =
        selectedWeek != null
            ? getDateList(selectedWeek.startDate, selectedWeek.endDate).map(
                  (it, index) => ({
                      title: format(it, 'M.d(EEE)', { locale: ko }),
                      dataIndex: `day${index + 1}`,
                      align: 'center',
                      customRender: (column: any) => {
                          return (
                              <Button type={'primary'}>
                                  <a
                                      href={`https://solution.pillyze.com/forme/report/${
                                          props.userId
                                      }?reportDate=${format(it, 'yyyy-MM-dd')}`}
                                      target="_blank"
                                  >
                                      조회
                                  </a>
                              </Button>
                          )
                      },
                  })
              )
            : []
    return [...dateTileList]
})

const findFactorReportDatasource = [
    {
        day1: '조회',
        day2: '조회',
        day3: '조회',
        day4: '조회',
        day5: '조회',
        day6: '조회',
        day7: '조회',
    },
]
</script>
<template>
    <a-spin tip="Loading..." :spinning="loadingPage">
        <a-flex vertical>
            <a-card>
                <template #title>
                    <a-typography-title
                        :level="4"
                        style="display: inline; margin-right: 10px"
                        >{{ userDetail.userName }}
                    </a-typography-title>
                    <a-typography-text type="secondary"
                        >{{ userDetail.age }}세 /
                        {{ userDetail.gender == 'MALE' ? '남' : '여' }} /
                        {{ userDetail.pregnant ? '임신' : '' }} ID
                        {{ userDetail.userId }} /
                        {{ userDetail.userAppVersion }} (최신:{{
                            userDetail.latestAppVersion
                        }}) / {{ userDetail.osType }} /
                        {{ `식단 ${userDetail.mealCount || 0}회 입력` }}
                        <router-link
                            v-if="userDetail.sensorCount"
                            :to="`${userId}/cgmDevice`"
                        >
                            {{ `/ 센서 ${userDetail.sensorCount || 0}개 사용` }}
                        </router-link>
                        {{ userDetail.usedVcgm ? '/ vCGM써 봄' : '' }}
                    </a-typography-text>
                    <a-space style="float: right">
                        <router-link :to="`/chat/${userDetail.userId}`">
                            <SendOutlined />
                            메세지
                            <a-badge :count="unreadCount" />
                        </router-link>
                    </a-space>
                </template>
                <a-descriptions :column="4">
                    <a-descriptions-item label="별명">
                        <div
                            class="nickName-container"
                            v-if="!editableNickName"
                        >
                            <span>{{ userDetail.nickName }}</span>
                            <img
                                alt="ic-edit-fill"
                                class="ic-edit"
                                src="https://cdn.pillyze.io/assets/d3bdf029a431f6bdd6515aed155cf991/ic/ic_edit_fill.webp"
                                @click="editNickName"
                            />
                        </div>
                        <div class="nickName-container" v-else>
                            <a-input
                                class="nickName-input"
                                :value="userDetail.nickName"
                                @update:value="changeNickName"
                            />
                            <a-button
                                type="primary"
                                class="nickName-btn"
                                @click="saveNickName"
                            >
                                확인
                            </a-button>
                        </div>
                    </a-descriptions-item>
                    <a-descriptions-item label="키"
                        >{{ userDetail.height }}
                    </a-descriptions-item>
                    <a-descriptions-item label="시작 체중"
                        >{{ userDetail.startWeight }}kg (BMI
                        {{ Math.round(userDetail.startBmi) || 0 }})
                    </a-descriptions-item>
                    <a-descriptions-item label="현재 체중"
                        >{{ userDetail.weight }}kg (BMI
                        {{ Math.round(userDetail.bmi) || 0 }})
                    </a-descriptions-item>
                    <a-descriptions-item label="목표 체중"
                        >{{ userDetail.targetWeight }}kg (BMI
                        {{ Math.round(userDetail.targetBmi) || 0 }})
                    </a-descriptions-item>
                    <a-descriptions-item label="건강고민">
                        {{ userDetail.higList?.join(', ') }}
                    </a-descriptions-item>
                    <a-descriptions-item label="건강 검진">
                        {{ userDetail.mcuList?.join(', ') }}
                    </a-descriptions-item>
                    <a-descriptions-item label="복용약물">
                        {{ userDetail.drugList?.join(', ') }}
                    </a-descriptions-item>
                    <a-descriptions-item label="알러지">
                        {{ userDetail.allergyList?.join(', ') }}
                    </a-descriptions-item>
                    <a-descriptions-item label="기저질환">
                        {{ userDetail.diseaseList?.join(', ') }}
                    </a-descriptions-item>
                    <a-descriptions-item label="가족력">
                        {{ userDetail.familyDiseaseList?.join(', ') }}
                    </a-descriptions-item>
                    <a-descriptions-item label="체형 고민">
                        {{ userDetail.bodyShapeList?.join(', ') }}
                    </a-descriptions-item>
                    <a-descriptions-item label="비만 가족력">
                        {{ userDetail.familyObesityList?.join(', ') }}
                    </a-descriptions-item>
                    <a-descriptions-item label="목표 혈당">
                        <div class="tbg-container" v-if="!editableTbg">
                            <span>{{ userDetail.tbgMin }}</span>
                            <span>&nbsp;/&nbsp;</span>
                            <span>{{ userDetail.tbgMax }}</span>
                            <img
                                alt="ic-edit-fill"
                                class="ic-edit"
                                src="https://cdn.pillyze.io/assets/d3bdf029a431f6bdd6515aed155cf991/ic/ic_edit_fill.webp"
                                @click="editTbg"
                            />
                        </div>
                        <div class="tbg-container" v-else>
                            <a-input-number
                                class="tbg-input"
                                :value="userDetail.tbgMin"
                                @change="changeTbgMin"
                            />
                            <span>&nbsp;/&nbsp;</span>
                            <a-input-number
                                class="tbg-input"
                                :value="userDetail.tbgMax"
                                @change="changeTbgMax"
                            />
                            <a-button
                                type="primary"
                                class="tbg-btn"
                                @click="saveTbg"
                            >
                                확인
                            </a-button>
                        </div>
                    </a-descriptions-item>
                    <a-descriptions-item label="목표 칼로리">
                        <div class="meal-container">
                            <span>{{ userDetail.targetCal }}kcal</span>
                            <img
                                alt="ic-edit-fill"
                                class="ic-edit"
                                src="https://cdn.pillyze.io/assets/d3bdf029a431f6bdd6515aed155cf991/ic/ic_edit_fill.webp"
                                @click="openMealModal"
                            />
                        </div>
                    </a-descriptions-item>
                    <a-descriptions-item label="목표 식단">
                        <div class="meal-container">
                            <span>{{ userDetail.selectedDiet }}</span>
                            <img
                                alt="ic-edit-fill"
                                class="ic-edit"
                                src="https://cdn.pillyze.io/assets/d3bdf029a431f6bdd6515aed155cf991/ic/ic_edit_fill.webp"
                                @click="openMealModal"
                            />
                        </div>
                    </a-descriptions-item>
                    <a-descriptions-item label="목표 탄단지 비율">
                        <div class="meal-container">
                            <span>
                                {{ userDetail.targetCarbohydrate }}:{{
                                    userDetail.targetProtein
                                }}:{{ userDetail.targetFat }}
                            </span>
                            <img
                                alt="ic-edit-fill"
                                class="ic-edit"
                                src="https://cdn.pillyze.io/assets/d3bdf029a431f6bdd6515aed155cf991/ic/ic_edit_fill.webp"
                                @click="openMealModal"
                            />
                        </div>
                    </a-descriptions-item>
                </a-descriptions>
                <a-form :label-col="{ span: 2 }" :label-align="'left'">
                    <a-form-item label="범인 타입">
                        <a-space wrap>
                            <draggable
                                v-model="userDetail.dietFactorTypeList"
                                @change="onReorderDietFactorTypeList"
                                item-key="dietFactorType"
                            >
                                <template #item="{ element }">
                                    <a-tag
                                        :key="element.dietFactorType"
                                        @close="
                                            handleDietFactorClose(
                                                element.dietFactorType
                                            )
                                        "
                                        closable
                                    >
                                        <a-typography-text strong
                                            >{{ element.category + ' ' }}
                                        </a-typography-text>
                                        <a-typography-text
                                            >{{ element.reason }}
                                        </a-typography-text>
                                    </a-tag>
                                </template>
                            </draggable>
                            <a-auto-complete
                                style="width: 200px"
                                v-if="searchInputVisible"
                                :options="dietFactorTypeOptions"
                                @select="selectDietFactorType"
                                @search="searchDietFactorType"
                                @blur="blurInput"
                            />
                            <a-tag
                                v-else-if="
                                    userDetail.dietFactorTypeList &&
                                    userDetail.dietFactorTypeList.length > 0
                                "
                                style="background: #fff; border-style: dashed"
                                @click="showInput"
                            >
                                <PlusOutlined />
                                팩터 추가
                            </a-tag>
                        </a-space>
                        <a-space style="float: right" direction="vertical">
                            <div style="float: right">
                                <span v-if="findFactorFail">
                                    범인 찾기 실패
                                </span>
                                <span
                                    v-if="userDetail.findFactorAt"
                                    style="margin-right: 10px"
                                >
                                    범인찾기 날짜:
                                    {{
                                        userDetail.findFactorAt != null
                                            ? formatLocalDateTime(
                                                  userDetail.findFactorAt
                                              )
                                            : ' '
                                    }}
                                </span>
                                <a-button
                                    @click="createMissionByDietFactor"
                                    :loading="loading"
                                    :disabled="
                                        !userDetail.findFactorEnable ||
                                        userDetail.findFactorAt
                                    "
                                >
                                    범인 찾기 실행
                                </a-button>
                            </div>
                            <div style="float: right">
                                <span
                                    v-if="userDetail.findFactorCompletedAt"
                                    style="margin-right: 10px"
                                >
                                    전송 시간:
                                    {{
                                        userDetail.findFactorCompletedAt != null
                                            ? formatLocalDateTime(
                                                  userDetail.findFactorCompletedAt
                                              )
                                            : ' '
                                    }}
                                </span>
                                <a-button
                                    @click="completeFindFactor"
                                    type="primary"
                                    :loading="loading"
                                    :disabled="userDetail.findFactorCompletedAt"
                                >
                                    범인찾기 리포트 알림톡 전송 완료
                                </a-button>
                            </div>
                        </a-space>
                    </a-form-item>
                    <a-form-item
                        label="WM 메모"
                        :help="'웰니스마스터만 확인하는 내용으로, 유저에게 공개되지 않아요.'"
                    >
                        <tiptap
                            :value="userDetail.wmMemo"
                            @update:value="onWriteWmNote"
                        />
                    </a-form-item>
                    <a-collapse v-model:active-key="collapseKey">
                        <a-collapse-panel key="1" header="더 보기">
                            <a-form-item label="문진표">
                                <span>
                                    {{ userDetail.wellnessSurvey?.answer }}
                                </span>
                            </a-form-item>
                            <a-form-item label="검진결과 공유">
                                <span>
                                    {{
                                        userDetail.wellnessSurvey?.healthCheckup
                                    }}
                                </span>
                            </a-form-item>
                            <a-form-item label="다이어트 경험">
                                <span>
                                    {{
                                        userDetail.wellnessSurvey
                                            ?.dietExperience
                                    }}
                                </span>
                            </a-form-item>
                            <a-form-item label="다이어트 목표">
                                <span>
                                    {{
                                        userDetail.wellnessSurvey?.reasonForDiet
                                    }}
                                </span>
                            </a-form-item>
                            <a-form-item label="셀프 원인 분석">
                                <span>
                                    {{ userDetail.wellnessSurvey?.guessReason }}
                                </span>
                            </a-form-item>
                            <a-form-item label="노션 링크">
                                <a-input
                                    :value="userDetail.notionLink"
                                    @update:value="onWriteNotionLink"
                                >
                                    <template #addonAfter>
                                        <a
                                            :href="userDetail.notionLink"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <LinkOutlined />
                                        </a>
                                    </template>
                                </a-input>
                            </a-form-item>
                        </a-collapse-panel>
                    </a-collapse>
                    <a-collapse
                        v-if="conditionSurvey && conditionSurvey.length"
                        style="margin-top: 20px"
                    >
                        <a-collapse-panel
                            v-for="(item, index) in conditionSurvey"
                            :key="index"
                            :header="`${index + 1}차 컨디션 서베이`"
                        >
                            <ul>
                                <li v-for="(answer, i) in item" :key="i">
                                    {{ i + 1 }}. {{ answer }}
                                </li>
                            </ul>
                        </a-collapse-panel>
                    </a-collapse>
                    <a-form-item>
                        <div
                            class="survey-check-container"
                            style="display: flex; margin-top: 20px"
                        >
                            <a-checkbox
                                v-model:checked="userDetail.survey1"
                                @change="toggleSatisfactionSurvey(1)"
                                >4주 만족도 조사 완료
                            </a-checkbox>
                            <a-checkbox
                                v-model:checked="userDetail.survey2"
                                @change="toggleSatisfactionSurvey(2)"
                                >8주 만족도 조사 완료
                            </a-checkbox>
                            <a-checkbox
                                v-model:checked="userDetail.survey3"
                                @change="toggleSatisfactionSurvey(3)"
                                >12주 만족도 조사 완료
                            </a-checkbox>
                        </div>
                    </a-form-item>
                </a-form>
            </a-card>
            <a-form style="margin-top: 48px">
                <a-form-item style="display: flex">
                    <div class="wm-container">
                        <a-form-item class="wm-form-item" label="메인 WM">
                            <enum-select
                                style="width: auto; margin-right: 10px"
                                v-model:value="mainWm"
                                :type-name="'forme-wellness-master'"
                            />
                            <a-button
                                class="wm-btn-save"
                                type="primary"
                                :loading="loading"
                                @click="saveUserMasters"
                            >
                                저장
                            </a-button>
                            <a-button
                                type="primary"
                                class="wm-btn-assign"
                                :disabled="hasAssignMessage"
                                :loading="loading"
                                @click="sendWMAssignMessage"
                            >
                                WM 배정 알림톡 전송
                                <div
                                    v-if="assignMessageSendTime"
                                    class="wm-assign-message"
                                >
                                    {{ assignMessageSendTime }}
                                </div>
                            </a-button>
                        </a-form-item>
                        <a-form-item class="wm-form-item" label="서브 WM">
                            <enum-select
                                style="width: auto; margin-right: 10px"
                                v-model:value="subWm"
                                :type-name="'forme-wellness-master'"
                                :mode="'multiple'"
                            />
                            <a-button
                                class="wm-btn-save"
                                type="primary"
                                :loading="loading"
                                @click="saveUserMasters"
                            >
                                저장
                            </a-button>
                        </a-form-item>
                    </div>
                    <a-form-item class="wm-form-item" label="버디 WM">
                        <enum-select
                            style="width: auto; margin-right: 10px"
                            v-model:value="buddyWm"
                            :type-name="'forme-wellness-master'"
                        />
                        <a-button
                            class="wm-btn-save"
                            type="primary"
                            :loading="loading"
                            @click="saveUserMasters"
                        >
                            저장
                        </a-button>
                    </a-form-item>
                </a-form-item>
                <a-form-item>
                    <a-radio-group
                        :value="filterParams.category"
                        @update:value="onUpdateCategory"
                    >
                        <a-radio-button>전체</a-radio-button>
                        <a-radio-button value="체중">체중</a-radio-button>
                        <a-radio-button value="혈당">혈당</a-radio-button>
                        <a-radio-button value="식단">식단</a-radio-button>
                        <a-radio-button value="활동">활동</a-radio-button>
                        <a-radio-button value="수면">수면</a-radio-button>
                    </a-radio-group>
                </a-form-item>
                <a-form-item>
                    <WeekSelectInput
                        :user-id="userId"
                        v-model:value="filterParams.weekNumber"
                        @after-update="fetchData"
                    />
                </a-form-item>
                <a-form-item
                    label="WM 위클리 하이라이트"
                    :help="'유저에게 전달하는 메시지로, [메시지 전달] 버튼을 누르면 앱으로 전달돼요.'"
                >
                    <tiptap v-model:value="weeklyMessage" />
                </a-form-item>
                <a-form-item>
                    <div style="float: right">
                        <span style="margin-right: 10px">
                            최근 전송 시간:
                            {{
                                formatLocalDateTime(userDetail.wmMessageWriteAt)
                            }}
                        </span>
                        <a-button
                            @click="() => writeWeeklyMessage(userId)"
                            :loading="loading"
                            type="primary"
                            :disabled="userDetail.wmMessageWriteAt || isFuture"
                            >메세지 전송
                        </a-button>
                    </div>
                </a-form-item>
            </a-form>
            <a-menu
                class="pt-10 pb-10"
                v-model:selected-keys="selectedTable"
                mode="horizontal"
                :items="tableMenu"
                @select="fetchTable"
            ></a-menu>
            <NestedResourceTable
                v-show="selectedTable.includes('mission')"
                ref="table"
                title="미션"
                create-modal-title="커스텀 미션 추가"
                :params="filterParams"
                :uri="`/api/forme/diet/mission/${userId}`"
                :columns="columns"
                :create-spec="createSpec"
                :create-rules="rules"
                :update-spec="updateSpec"
                :update-rules="rules"
                primary-key="userMissionId"
                :scroll="{ x: 'max-content' }"
            >
                <template #toolbar>
                    <span
                        v-if="userDetail.sendMission"
                        style="margin-right: 10px"
                        >최근 전송 시간:
                        {{ formatLocalDateTime(userDetail.weekCompleteAt) }}
                    </span>
                    <a-button
                        type="primary"
                        @click="completeMission"
                        :loading="loading"
                        :disabled="userDetail.sendMission || isFuture"
                        >미션 알림톡 전송
                    </a-button>
                </template>
            </NestedResourceTable>
            <NestedResourceTable
                v-show="selectedTable.includes('data')"
                ref="dataTable"
                title="데이터"
                :uri="`/api/forme/diet/monitor/${userId}`"
                :columns="monitorColumns"
                :params="filterParams"
                :scroll="{ x: 'max-content' }"
                :load-on-mount="false"
            />
            <a-table
                v-show="selectedTable.includes('findFactor')"
                :data-source="findFactorReportDatasource"
                :columns="findFactorReportColumns"
            />
        </a-flex>
    </a-spin>
    <a-modal
        class="modal-meal-info"
        title="식단 목표 수정"
        v-model:open="cpfEditable"
        width="70%"
        cancel-text="취소"
        ok-text="수정"
        @ok="saveCpf"
    >
        <a-descriptions :layout="'horizontal'" :column="1" :bordered="true">
            <a-descriptions-item label="BMR">
                <span>{{ Math.round(userDetail.bmr) }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="목표 식단">
                <enum-select
                    type-name="diet-name"
                    :value="userDetail.selectedDiet"
                    @select="selectDiet"
                />
            </a-descriptions-item>
            <a-descriptions-item label="목표 칼로리">
                <div class="modal-content-container">
                    <a-input-number
                        class="modal-input"
                        :value="userDetail.targetCal"
                        @change="changeCalorie"
                    />
                    <span>&nbsp;kcal</span>
                </div>
            </a-descriptions-item>
            <a-descriptions-item label="목표 탄단지 비율">
                <div class="modal-content-container content-cpf">
                    <section class="modal-content-section carbohydrate-section">
                        <a-input-number
                            class="modal-input"
                            min="0"
                            max="100"
                            :value="userDetail.targetCarbohydrate"
                            @change="changeCarbohydrate"
                        />
                        <span class="modal-input plain-text">
                            {{ carbohydrateGram }}g
                        </span>
                    </section>
                    <section class="modal-content-section protein-section">
                        <a-input-number
                            class="modal-input"
                            min="0"
                            max="100"
                            :value="userDetail.targetProtein"
                            @change="changeProtein"
                        />
                        <span class="modal-input plain-text">
                            {{ proteinGram }}g
                        </span>
                        <span class="modal-input plain-text">
                            체중 {{ proteinWeightNx }}배
                        </span>
                    </section>
                    <section class="modal-content-section fat-section">
                        <a-input-number
                            class="modal-input"
                            min="0"
                            max="100"
                            :value="userDetail.targetFat"
                            @change="changeFat"
                        />
                        <span class="modal-input plain-text">
                            {{ fatGram }}g
                        </span>
                    </section>
                </div>
            </a-descriptions-item>
        </a-descriptions>
    </a-modal>
    <router-view />
</template>
