import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { message } from 'ant-design-vue'
import SqlEditor from './SqlEditor.vue'
import { OptionProps } from 'ant-design-vue/es/select'

type Segment = {
    segId: number
    selectionQuery: string
    readonly: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SegmentInput',
  props: {
    name: {
        type: String,
    },
    value: {
        type: String,
        required: true,
    },
},
  emits: ['update:value'],
  setup(__props, { emit: __emit }) {



const segment = ref<Segment>({
    segId: 0,
    selectionQuery: '',
    readonly: true,
})

const fetchSegment = async (id: string) => {
    try {
        segment.value.readonly = false
        const response = await adminApi<ApiResponse<Segment>>(
            `/api/segments/${id}`
        )
        segment.value = response.result || {
            segId: 0,
            selectionQuery: '',
            readonly: true,
        }
    } catch (error) {
        console.error(error)
        message.error('세그먼트 정보를 불러오는데 실패했습니다.')
    }
}

const emits = __emit

const segIdList = ref<OptionProps[]>([])

const onSearch = async (value: string) => {
    if (value.length === 0) {
        return
    }
    segIdList.value.length = 0
    const response = await adminApi<ApiResponse<string[]>>(
        `/api/segments/search/${value}`
    )
    segIdList.value.push(
        ...(response.result || []).map((id) => ({
            value: id,
            label: id,
        }))
    )
}

const onSelect = async (value: string) => {
    if (value) {
        emits('update:value', value)
        await fetchSegment(value)
    }
}

const queryResult = ref<string>('')
const loadingQuery = ref<boolean>(false)
const onQuery = async () => {
    loadingQuery.value = true
    try {
        const response = await adminApi<ApiResponse<string[]>>(
            `/api/segments/query/${segment.value.segId}`,
            {
                method: 'POST',
            }
        )
        queryResult.value = response.result?.join(', ') || ''
    } catch (error) {
        console.error(error)
        message.error('쿼리 실행에 실패했습니다.')
    } finally {
        loadingQuery.value = false
    }
}

return (_ctx: any,_cache: any) => {
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createBlock(_component_a_space, {
    direction: "vertical",
    class: "w-[100%]"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_auto_complete, {
        options: segIdList.value,
        onSelect: onSelect,
        onSearch: onSearch
      }, null, 8, ["options"]),
      _createVNode(SqlEditor, {
        value: segment.value.selectionQuery,
        readonly: segment.value.readonly
      }, {
        button: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: onQuery,
            loading: loadingQuery.value
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("실행")
            ])),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      }, 8, ["value", "readonly"]),
      _createVNode(_component_a_typography_text, {
        type: "secondary",
        class: "mt-2"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("쿼리 결과로 userId 목록을 추출합니다.")
        ])),
        _: 1
      }),
      _createVNode(_component_a_textarea, { value: queryResult.value }, null, 8, ["value"])
    ]),
    _: 1
  }))
}
}

})