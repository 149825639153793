import {
    FormSpecification,
    InputSpecification,
} from '@/components/InputMapper.vue'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash'

type FormState = {
    [name: string]: {
        spec: FormSpecification
        defaultSpec: FormSpecification
    }
}

export const useFormStore = defineStore(
    'form',
    () => {
        const formState = ref<FormState>({})

        const resetForm = (name: string) => {
            if (!formState.value[name]) return
            formState.value[name].spec = cloneDeep(
                formState.value[name].defaultSpec
            )
        }

        const cleanAll = () => {
            formState.value = {}
        }

        const setFormValues = (name: string, resource: Record<any, any>) => {
            if (!formState.value[name]) {
                return
            }
            Object.keys(resource).forEach((key: any) => {
                if (formState.value[name].spec[key]) {
                    formState.value[name].spec[key].value = resource[key]
                }
            })
        }

        const setFormValue = (name: string, key: string, value: any) => {
            if (!formState.value[name]) {
                return
            }
            formState.value[name].spec[key].value = value
        }

        const setInputSpec = (
            name: string,
            resourceName: string,
            inputSpec: InputSpecification
        ) => {
            if (!formState.value[name]) {
                return
            }

            formState.value[name].spec[resourceName] = { ...inputSpec }
        }

        const setFormSpec = (name: string, spec: FormSpecification) => {
            formState.value[name] = {
                spec: cloneDeep(spec),
                defaultSpec: cloneDeep(spec),
            }
        }

        const getRecord = (name: string): Record<any, any> => {
            if (!formState.value[name]) {
                return {}
            }

            return Object.keys(formState.value[name].spec).reduce(
                (record, resourceName) => {
                    if (
                        formState.value[name].spec[resourceName].value == null
                    ) {
                        return { ...record }
                    }

                    return {
                        ...record,
                        [resourceName]:
                            formState.value[name].spec[resourceName].value,
                    }
                },
                {}
            )
        }

        const getRecordKeys = (name: string): Array<string> => {
            if (!formState.value[name]) {
                return []
            }

            return Object.keys(formState.value[name].spec)
        }

        return {
            formState,
            resetForm,
            cleanAll,
            setFormValues,
            setFormValue,
            setInputSpec,
            setFormSpec,
            getRecord,
            getRecordKeys,
        }
    },
    {
        persist: true,
    }
)
