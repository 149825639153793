<script setup lang="ts">
import { defineEmits, defineProps, onMounted, onUnmounted, ref } from 'vue'
import { InputComponents, SelectInputMode } from '@/components/InputMapper.vue'
import { useFormStore } from '@/store/form'
import PForm from '@/components/PForm.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { useFormeWeekStore } from '@/store/formeWeek'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { debounce } from 'lodash'
import { promptPreset, weeklyPreset } from '@/components/formePromptPreset'
import { required } from '@/util/input-validation'
import { message } from 'ant-design-vue'
import { stringify } from 'qs'

const { formState, setFormSpec, getRecord, setFormValue } = useFormStore()

const props = defineProps({
    userId: {
        type: Number,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
})

const emits = defineEmits(['afterSubmit'])

const waitMessage = ref(false)

let eventSource: EventSource | null = null
const buffer = ref<string[]>([])

const subscribeResponse = async () => {
    buffer.value = []
    if (eventSource) {
        eventSource.close()
    }

    const prompt = await putPrompt()
    emits('afterSubmit')

    const baseUrl = `${process.env.VUE_APP_BASEURL}`
    let queryString = ''
    if (
        formState[props.name].spec.period.value &&
        formState[props.name].spec.period.value.length > 0
    ) {
        queryString = stringify({
            startDate: formState[props.name].spec.period.value[0],
            endDate: formState[props.name].spec.period.value[1],
        })
    }
    const apiUrl = `${baseUrl}/api/forme/prompt/generate-message/stream/${
        prompt.id
    }/${formState[props.name].spec.userId.value}?${queryString}`
    eventSource = new EventSource(apiUrl, { withCredentials: true })

    eventSource.addEventListener('forme-message-generate', (event) => {
        const data = JSON.parse(event.data)
        if (data.message !== '__END__') {
            buffer.value.push(data.message)
            setFormValue(props.name, 'answer', buffer.value.join(''))
        } else {
            waitMessage.value = false
        }
    })

    eventSource.onerror = () => {
        waitMessage.value = false
        eventSource?.close()
    }
}

const cancelSubscription = () => {
    if (eventSource) {
        waitMessage.value = false
        eventSource.close()
    }
}

const putPrompt = async () => {
    const record = getRecord(props.name)
    try {
        waitMessage.value = true
        const response = await adminApi<ApiResponse<any>>('/api/forme/prompt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(record),
        })

        return response.result
    } catch (e) {
        waitMessage.value = false
        console.error(e)
        message.error('프롬프트 생성에 실패했습니다.')
    }
}

const rules = {
    userId: [required],
    gptModel: [
        { required: true, message: 'gpt 모델을 선택해주세요', trigger: 'blur' },
    ],
    prompt: [
        { required: true, message: '프롬프트를 입력해주세요', trigger: 'blur' },
    ],
}

const { formeWeek, getWeekList, cleanFormeWeek } = useFormeWeekStore()

onMounted(async () => {
    if (props.userId) {
        await getWeekList(props.userId)
    } else {
        cleanFormeWeek()
    }
    setFormSpec(props.name, {
        userId: {
            label: '사용자 ID',
            component: InputComponents.AInputNumber,
            value: props.userId,
            input: debounce(async (value: number) => {
                if (value) {
                    await getWeekList(value)
                }
            }, 500),
        },
        alias: {
            label: 'name',
        },
        gptModel: {
            label: 'gpt 모델',
            component: InputComponents.EnumSelect,
            typeName: 'gpt-model',
            value: 'GPT_4O',
        },
        period: {
            label: '기간',
            component: InputComponents.ARangeDatePicker,
        },
        weekNumber: {
            label: '주차',
            component: InputComponents.WeekSelectInput,
            input: (week: number) => {
                const selectedWeek = formeWeek.selectedWeek
                setFormValue(props.name, 'period', [
                    selectedWeek.startDate,
                    selectedWeek.endDate,
                ])
                const preset = weeklyPreset.find((it) => it.week === week)
                if (preset) {
                    setFormValue(props.name, 'prompt', preset.prompt)
                }
            },
        },
        dataList: {
            label: '데이터',
            component: InputComponents.EnumSelect,
            mode: SelectInputMode.MULTIPLE,
            typeName: 'forme-prompt-data',
        },
        prompt: {
            label: '프롬프트',
            component: InputComponents.FormePromptPresetInput,
            options: promptPreset,
            select: (value: string, option: Record<string, any>) => {
                if (option.dataList) {
                    setFormValue(props.name, 'dataList', option.dataList)
                }
            },
        },
        answer: {
            label: '답변',
            component: InputComponents.Tiptap,
        },
    })

    useEnumTypeStore().dispatchEnums(['gpt-model', 'forme-prompt-data'])
})

onUnmounted(() => {
    if (eventSource) {
        eventSource.close()
    }
})
</script>

<template>
    <a-flex justify="center">
        <PForm
            title="포미 프롬프트"
            :input-spec="formState[name].spec"
            @submit="subscribeResponse"
            :rules="rules"
            :loading="waitMessage"
            disable-skeleton
        >
            <template #action>
                <a-button @click="cancelSubscription" danger>중단</a-button>
            </template>
            <template #button>생성</template>
        </PForm>
    </a-flex>
</template>

<style scoped></style>
