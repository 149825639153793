import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end" }

import { adminApi, ApiResponse } from '@/fetchTemplate'
import { ref, onMounted } from 'vue'
import { OptionProps } from 'ant-design-vue/es/select'
import { message } from 'ant-design-vue'
import { debounce } from 'lodash'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormePromptPresetInput',
  props: {
    value: {
        type: String,
        required: true,
    },
    dataList: {
        type: Array,
    },
},
  emits: ['update:value', 'select'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const presetList = ref<OptionProps[]>([])

const initPreset = async () => {
    const response = await adminApi<ApiResponse<any[]>>(
        `/api/forme/diet/prompt-preset?presetType=FORME_PROMPT`
    )
    presetList.value =
        response.result?.map((preset) => ({
            value: preset.promptList[0],
            label: preset.name,
            dataList: preset.dataList,
        })) || []
}

const fetchPreset = debounce(async (value: string) => {
    try {
        const response = await adminApi<ApiResponse<any[]>>(
            `/api/forme/diet/prompt-preset/${value}?presetType=FORME_PROMPT`
        )
        presetList.value =
            response.result?.map((preset) => ({
                value: preset.promptList[0],
                label: preset.name,
                dataList: preset.dataList,
            })) || []
    } catch (error) {
        console.error(error)
        message.error('프리셋 정보를 불러오는데 실패했습니다.')
    }
}, 500)

const searchText = ref('')

const onSearch = async (value: string) => {
    await fetchPreset(value)
}

const selectedOption = ref<OptionProps | null>(null)

const onSelect = (value: string, option: Record<string, any>) => {
    selectedOption.value = option
    prompt.value = value
    searchText.value = option.label
    emits('update:value', value)
    emits('select', value, option)
}

const prompt = ref(props.value)

const onUpdate = (value: string) => {
    prompt.value = value
    emits('update:value', value)
}

const loading = ref(false)
const onSave = async () => {
    try {
        loading.value = true
        await adminApi<ApiResponse<any>>(`/api/forme/diet/prompt-preset`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: searchText.value,
                presetType: 'FORME_PROMPT',
                promptList: [prompt.value],
                dataList: props.dataList,
            }),
        })
    } catch (error) {
        console.error(error)
        message.error('프리셋 저장에 실패했습니다.')
    } finally {
        loading.value = false
    }
}

const onDelete = async () => {
    try {
        loading.value = true
        await adminApi<ApiResponse<any>>(
            `/api/forme/diet/prompt-preset/${searchText.value}?presetType=FORME_PROMPT`,
            {
                method: 'DELETE',
            }
        )
    } catch (error) {
        console.error(error)
        message.error('프리셋 삭제에 실패했습니다.')
    } finally {
        loading.value = false
    }
}

onMounted(() => {
    initPreset()
})

return (_ctx: any,_cache: any) => {
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space_compact = _resolveComponent("a-space-compact")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createBlock(_component_a_space, {
    direction: "vertical",
    class: "w-[100%]"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_auto_complete, {
        value: searchText.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
        options: presetList.value,
        onSearch: onSearch,
        onSelect: onSelect
      }, null, 8, ["value", "options"]),
      _createVNode(_component_a_textarea, {
        value: __props.value,
        "onUpdate:value": onUpdate,
        rows: 10
      }, null, 8, ["value"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_space_compact, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              onClick: onDelete,
              loading: loading.value,
              disabled: !searchText.value,
              danger: ""
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("삭제")
              ])),
              _: 1
            }, 8, ["loading", "disabled"]),
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: onSave,
              disabled: !searchText.value || !prompt.value,
              loading: loading.value
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("저장")
              ])),
              _: 1
            }, 8, ["disabled", "loading"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})