<script setup lang="ts">
import FormePromptForm from '@/components/FormePromptForm.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { formatEnumTags } from '@/util/formmater'
import {
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { defineProps, onMounted, ref } from 'vue'
import { useFormStore } from '@/store/form'
import { CopyOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { useEnumTypeStore } from '@/store/enumType'
import router from '@/router'

defineProps({
    userId: {
        type: Number,
        required: true,
    },
})

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 80,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '프롬프트',
        dataIndex: 'prompt',
        customCell: () => {
            return {
                style: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            }
        },
    },
    {
        title: '이름',
        dataIndex: 'alias',
    },
    {
        title: '데이터',
        dataIndex: 'dataList',
        customRender: formatEnumTags('forme-prompt-data'),
    },
    {
        title: '수정자',
        dataIndex: 'updatedBy',
        width: 120,
        align: 'center',
    },
    {
        title: '복사',
        type: 'customAction',
        width: 80,
        align: 'center',
        fixed: 'right',
    },
]

const inputSpec = {
    gptModel: {
        label: 'gpt 모델',
        component: InputComponents.EnumSelect,
        typeName: 'gpt-model',
    },
    alias: {
        label: 'name',
    },
    period: {
        label: '기간',
        component: InputComponents.ARangeDatePicker,
    },
    dataList: {
        label: '데이터',
        component: InputComponents.EnumSelect,
        mode: SelectInputMode.MULTIPLE,
        typeName: 'forme-prompt-data',
    },
    prompt: {
        label: '프롬프트',
        type: InputType.TEXTAREA,
        height: '300px',
    },
    answer: {
        label: '답변',
        component: InputComponents.Tiptap,
    },
}

const filterSpec = {
    alias: {
        label: '이름',
    },
}

const { setFormValues } = useFormStore()

const copyForm = (record: any) => {
    setFormValues('formePromptMaker', record)
    message.success('프롬프트가 복사되었습니다.')
}

const tableRef = ref()

const onAfterSubmit = () => {
    tableRef.value?.reloadPage()
}

const visible = ref(true)

const toggleVisible = () => {
    visible.value = !visible.value
    router.back()
}

onMounted(() => {
    useEnumTypeStore().dispatchEnums(['gpt-model', 'forme-prompt-data'])
})
</script>

<template>
    <a-drawer :visible="true" @close="toggleVisible" width="900px">
        <FormePromptForm
            :user-id="userId"
            name="formePromptMaker"
            @after-submit="onAfterSubmit"
        />
        <ResourceTable
            ref="tableRef"
            title="프롬프트"
            resource-name="formeMessagePrompt"
            uri="/api/forme/prompt"
            :columns="columns"
            :create-spec="inputSpec"
            :update-spec="inputSpec"
            deletable
            load-on-mount
            :scroll="{ x: 1000 }"
            :filter-spec="filterSpec"
        >
            <template #customAction="{ record }">
                <CopyOutlined
                    style="cursor: pointer"
                    @click="copyForm(record)"
                />
            </template>
        </ResourceTable>
    </a-drawer>
</template>

<style scoped></style>
