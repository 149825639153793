// AutoResizableMediaExtension.ts
import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

export const AutoResizableMediaExtension = Extension.create({
    name: 'autoResizableMediaExtension',

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('autoResizableMediaPlugin'),
                props: {
                    handlePaste: (view, event) => {
                        const text = event.clipboardData?.getData('text/plain')
                        if (!text) return false

                        // 예시로 이미지/비디오를 구분할 간단한 정규식
                        // 필요시 확장/수정하세요.
                        const imageRegex =
                            /\.(?:png|jpe?g|gif|svg|webp)(\?.*)?$/i
                        const videoRegex = /\.(?:mp4|webm|ogg)(\?.*)?$/i

                        // 붙여넣은 텍스트가 이미지 URL인지 체크
                        if (imageRegex.test(text)) {
                            const { schema, tr } = view.state
                            // schema.nodes.resizableMedia 가 존재하는지 확인
                            const nodeType = schema.nodes.resizableMedia
                            if (!nodeType) return false

                            const node = nodeType.create({
                                src: text,
                                'media-type': 'img',
                            })

                            view.dispatch(
                                tr.replaceSelectionWith(node).scrollIntoView()
                            )
                            return true
                        }

                        // 붙여넣은 텍스트가 비디오 URL인지 체크
                        if (videoRegex.test(text)) {
                            const { schema, tr } = view.state
                            const nodeType = schema.nodes.resizableMedia
                            if (!nodeType) return false

                            const node = nodeType.create({
                                src: text,
                                'media-type': 'video',
                            })

                            view.dispatch(
                                tr.replaceSelectionWith(node).scrollIntoView()
                            )
                            return true
                        }

                        return false
                    },
                },
            }),
        ]
    },
})
