<template>
    <a-row v-if="!hidden">
        <a-col span="24">
            <form-item
                v-if="inputSpec.readonly === true"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <span v-if="value && value.length && typeof value !== 'string'">
                    <a-tag v-for="tag of value" :key="tag" color="cyan">{{
                        tag
                    }}</a-tag>
                </span>
                <span v-else>
                    <p>
                        <b v-if="inputSpec.type === 'pillz'">
                            {{
                                `${getPillzText(form.feedbackScore)} ${
                                    form.feedbackAt
                                        ? `(${new Date(
                                              form.feedbackAt
                                          ).toLocaleString()})`
                                        : ''
                                }`
                            }}
                        </b>
                    </p>
                    {{ value }}
                </span>
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AInputNumber'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :hidden="hidden"
            >
                <a-input-number
                    v-if="!infinite"
                    v-bind="inputSpec"
                    v-model:value="value"
                    :min="0"
                    :disabled="disabled"
                    :hidden="hidden"
                    @change="inputSpec.input"
                    @keyup.enter="checkKeyup"
                    :style="{ width: inputSpec.width || 'fit-content' }"
                />
                <a-form-item-rest>
                    <a-switch
                        v-if="inputSpec.infinity"
                        v-model:checked="infinite"
                        checked-children="무한"
                        un-checked-children="0"
                        @click="
                            (checked: boolean) => {
                                if (checked) {
                                    setValue(9999999999999.0)
                                    infinite = true
                                } else {
                                    setValue(null)
                                    infinite = false
                                }
                            }
                        "
                        style="margin-left: 8px"
                    />
                </a-form-item-rest>
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ASelect'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <a-select
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    :options="inputSpec.options"
                    :style="{ width: inputSpec.width || '8em' }"
                    @select="inputSpec.select"
                >
                    <template #option="{ label, image }">
                        <span role="img" :aria-label="label">
                            {{ image }}
                        </span>
                        &nbsp;&nbsp;{{ label }}
                    </template>
                </a-select>
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ARangePicker'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <a-range-picker
                    v-bind="inputSpec"
                    v-model:value="value"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ARangeDatePicker'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <a-range-picker
                    v-bind="inputSpec"
                    v-model:value="value"
                    value-format="YYYY-MM-DD"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ATimePicker'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <a-time-picker
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @change="inputSpec.input"
                    @keyup.enter="$emit('press-enter')"
                    value-format="HH:mm:ss"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ADatePicker'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <a-date-picker
                    v-bind="inputSpec"
                    v-model:value="value"
                    value-format="YYYY-MM-DD"
                    :disabled="disabled"
                    @change="inputSpec.input"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ADatetimePicker'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <a-date-picker
                    v-bind="inputSpec"
                    v-model:value="value"
                    show-time
                    :value-format="inputSpec.format || 'YYYY-MM-DDTHH:mm:ss'"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'EnumSelect'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <enum-select
                    v-bind="inputSpec"
                    v-model:value="value"
                    :type-name="inputSpec.typeName"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                    @input="inputSpec.input"
                    @select="inputSpec.select"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'QuestionImageInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <question-image-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :type-name="inputSpec.typeName"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                    @input="inputSpec.input"
                    @select="inputSpec.select"
                    :resource-id="resourceId"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ProductNutrientInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <product-nutrient-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'SearchAdProductInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <search-ad-product-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'FoodNutrientInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <food-nutrient-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'SurveyOptionInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <survey-option-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'EventContentInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <event-content-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    :resource-id="resourceId"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'FoodIntakeUnitInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <food-intake-unit-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'NutrientInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <nutrient-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'BooleanInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <boolean-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ProductTagInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <product-tag-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @deselect="inputSpec.deselect"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'PresetInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <preset-input
                    v-bind="inputSpec"
                    :value="value"
                    :disabled="disabled"
                    :radio="inputSpec.radio"
                    @check="inputSpec.input"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AutoCompleteInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <auto-complete-input
                    v-bind="inputSpec"
                    :name="inputSpec.name"
                    :uri="inputSpec.uri"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'StandardUnitInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <standard-unit-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'UserInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <user-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'SqlEditor'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <SqlEditor
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @change="inputSpec.input"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'FormePromptPresetInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
            >
                <forme-prompt-preset-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    :data-list="form != null ? form.dataList : []"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'Tiptap'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <tiptap v-model:value="value" />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ProductInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <product-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @select="inputSpec.select"
                    :disabled="disabled"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'WorkoutInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <workout-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @select="inputSpec.select"
                    :disabled="disabled"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'FoodInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <food-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @select="inputSpec.select"
                    :disabled="disabled"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'MultipleProductInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <multiple-product-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @select="inputSpec.select"
                    :disabled="disabled"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ImageInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <image-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @select="inputSpec.select"
                    :disabled="disabled"
                    :form="form"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ContentImageInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                style="display: flex; align-items: center"
                :hidden="hidden"
            >
                <content-image-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @select="inputSpec.select"
                    :disabled="disabled"
                    style="display: flex; align-items: center"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'FoodIntakeInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <food-intake-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @select="inputSpec.select"
                    :disabled="disabled"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'FoodIngredientInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <food-ingredient-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @change="inputSpec.input"
                    :disabled="disabled"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'TemplateInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <template-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @change="inputSpec.input"
                    :disabled="disabled"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'ChatMessageInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <chat-message-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @change="inputSpec.input"
                    :disabled="disabled"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AdPropertyInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <ad-property-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AdSlotInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <ad-slot-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AdLayoutInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <ad-layout-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AdLayoutInputV2'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <ad-layout-input-v2
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AdEventHandlerInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <ad-event-handler-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AdVoInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <ad-vo-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AdMissionInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <ad-mission-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AdTargetUserInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <ad-target-user-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AdScheduleInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <ad-schedule-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'AdRequirementInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <ad-requirement-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @select="inputSpec.select"
                    @keyup.enter="$emit('press-enter')"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'CrmReplacementInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <crm-replacement-input
                    v-bind="inputSpec"
                    v-model:value="value"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'CrmBizmButtonInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <crm-bizm-button-input
                    v-bind="inputSpec"
                    v-model:value="value"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'WeekSelectInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <week-select-input
                    v-bind="inputSpec"
                    :user-id="inputSpec.userId"
                    :resource-id="resourceId"
                    v-model:value="value"
                    @after-update="inputSpec.input"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'FormeMissionInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
            >
                <forme-mission-input
                    v-bind="inputSpec"
                    @after-select="inputSpec.select"
                    v-model:value="value"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'InputCompleteInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <input-complete-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    @change="inputSpec.input"
                    :disabled="disabled"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.component === 'SegmentInput'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <SegmentInput
                    v-bind="inputSpec"
                    v-model:value="value"
                    @change="inputSpec.input"
                    :disabled="disabled"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.type === 'textarea'"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <a-textarea
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @keyup.enter="$emit('press-enter')"
                    :style="{
                        width: inputSpec.width || '550px',
                        height: inputSpec.height || 'auto',
                    }"
                />
            </form-item>
            <form-item
                v-else-if="inputSpec.copy"
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <a-typography-paragraph v-if="value" copyable>
                    {{ value }}
                </a-typography-paragraph>
            </form-item>
            <form-item
                v-else
                v-bind="inputSpec"
                :label="label"
                :label-col="{ span: labelCol }"
                :wrapper-col="{ span: wrapperCol }"
                :name="prop"
                :help="inputSpec.help"
                :color="inputSpec.color"
                :hidden="hidden"
            >
                <a-input
                    v-bind="inputSpec"
                    v-model:value="value"
                    :disabled="disabled"
                    @change="inputSpec.input"
                    @keyup.enter="$emit('press-enter')"
                    :style="{ width: inputSpec.width || '20em' }"
                />
            </form-item>
        </a-col>
    </a-row>
</template>

<script lang="ts">
import EnumSelect from '@/components/input/EnumSelect.vue'
import ProductNutrientInput from '@/components/input/ProductNutrientInput.vue'
import SearchAdProductInput from '@/components/input/SearchAdProductInput.vue'
import NutrientInput from '@/components/input/NutrientInput.vue'
import BooleanInput from '@/components/input/BooleanInput.vue'
import ProductTagInput from '@/components/input/ProductTagInput.vue'
import PresetInput from '@/components/input/PresetInput.vue'
import AutoCompleteInput from '@/components/input/AutoCompleteInput.vue'
import StandardUnitInput from '@/components/input/StandardUnitInput.vue'
import UserInput from '@/components/input/UserInput.vue'
import Tiptap from '@/components/input/tiptap/Tiptap.vue'
import SqlEditor from '@/components/input/SqlEditor.vue'
import ProductInput from './input/ProductInput.vue'
import FoodInput from './input/FoodInput.vue'
import MultipleProductInput from './input/MultipleProductInput.vue'
import { sanitizeUrl } from '@braintree/sanitize-url'
import { defineComponent, Ref, ref, toRef } from 'vue'
import FormItem from '@/components/input/FormItem.vue'
import FoodNutrientInput from '@/components/input/FoodNutrientInput.vue'
import FoodIntakeUnitInput from './input/FoodIntakeUnitInput.vue'
import FoodIntakeInput from './input/FoodIntakeInput.vue'
import FoodIngredientInput from './input/FoodIngredientInput.vue'
import ImageInput from '@/components/input/ImageInput.vue'
import ContentImageInput from '@/components/input/ContentImageInput.vue'
import TemplateInput from '@/components/input/TemplateInput.vue'
import ChatMessageInput from '@/components/input/ChatMessageInput.vue'
import InputCompleteInput from '@/components/input/InputCompleteInput.vue'
import WorkoutInput from '@/components/input/WorkoutInput.vue'
import SurveyOptionInput from '@/components/input/SurveyOptionInput.vue'
import { ChatMessage } from './input/inputComplete'
import QuestionImageInput from './input/QuestionImageInput.vue'
import EventContentInput from '@/components/input/EventContentInput.vue'
import AdLayoutInput from '@/components/input/advertisements/AdLayoutInput.vue'
import AdLayoutInputV2 from '@/components/input/advertisements/AdLayoutPropertyInput.vue'
import AdSlotInput from '@/components/input/advertisements/AdSlotInput.vue'
import AdEventHandlerInput from '@/components/input/advertisements/AdEventHandlerInput.vue'
import AdPropertyInput from '@/components/input/advertisements/AdPropertyInput.vue'
import AdVoInput from './input/advertisements/AdVoInput.vue'
import AdMissionInput from './input/advertisements/AdMissionInput.vue'
import AdTargetUserInput from '@/components/input/advertisements/AdTargetUserInput.vue'
import AdScheduleInput from '@/components/input/advertisements/AdScheduleInput.vue'
import AdRequirementInput from '@/components/input/advertisements/AdRequirementInput.vue'
import CrmReplacementInput from '@/components/input/CrmReplacementInput.vue'
import CrmBizmButtonInput from '@/components/input/CrmBizmButtonInput.vue'
import WeekSelectInput from '@/components/input/WeekSelectInput.vue'
import FormeMissionInput from '@/components/input/FormeMissionInput.vue'
import SegmentInput from '@/components/input/SegmentInput.vue'
import FormePromptPresetInput from '@/components/input/FormePromptPresetInput.vue'

export enum InputComponents {
    AInputNumber = 'AInputNumber',
    ARangePicker = 'ARangePicker',
    ARangeDatePicker = 'ARangeDatePicker',
    ATimePicker = 'ATimePicker',
    ADatePicker = 'ADatePicker',
    ADatetimePicker = 'ADatetimePicker',
    EnumSelect = 'EnumSelect',
    ProductNutrientInput = 'ProductNutrientInput',
    SearchAdProductInput = 'SearchAdProductInput',
    BooleanInput = 'BooleanInput',
    ProductTagInput = 'ProductTagInput',
    NutrientInput = 'NutrientInput',
    PresetInput = 'PresetInput',
    ASelect = 'ASelect',
    AutoCompleteInput = 'AutoCompleteInput',
    StandardUnitInput = 'StandardUnitInput',
    UserInput = 'UserInput',
    Tiptap = 'Tiptap',
    SqlEditor = 'SqlEditor',
    ProductInput = 'ProductInput',
    FoodInput = 'FoodInput',
    MultipleProductInput = 'MultipleProductInput',
    FoodNutrientInput = 'FoodNutrientInput',
    FoodIntakeUnitInput = 'FoodIntakeUnitInput',
    FoodIntakeInput = 'FoodIntakeInput',
    FoodIngredientInput = 'FoodIngredientInput',
    ImageInput = 'ImageInput',
    ContentImageInput = 'ContentImageInput',
    TemplateInput = 'TemplateInput',
    ChatMessageInput = 'ChatMessageInput',
    InputCompleteInput = 'InputCompleteInput',
    WorkoutInput = 'WorkoutInput',
    SurveyOptionInput = 'SurveyOptionInput',
    EventContentInput = 'EventContentInput',
    QuestionImageInput = 'QuestionImageInput',
    AdPropertyInput = 'AdPropertyInput',
    AdLayoutInput = 'AdLayoutInput',
    AdLayoutInputV2 = 'AdLayoutInputV2',
    AdSlotInput = 'AdSlotInput',
    AdEventHandlerInput = 'AdEventHandlerInput',
    AdVoInput = 'AdVoInput',
    AdMissionInput = 'AdMissionInput',
    AdTargetUserInput = 'AdTargetUserInput',
    AdScheduleInput = 'AdScheduleInput',
    AdRequirementInput = 'AdRequirementInput',
    CrmReplacementInput = 'CrmReplacementInput',
    CrmBizmButtonInput = 'CrmBizmButtonInput',
    WeekSelectInput = 'WeekSelectInput',
    FormeMissionInput = 'FormeMissionInput',
    SegmentInput = 'SegmentInput',
    FormePromptPresetInput = 'FormePromptPresetInput',
}

export enum SelectInputMode {
    TAGS = 'tags',
    MULTIPLE = 'multiple',
    DEFAULT = 'default',
}

export enum InputType {
    TEXTAREA = 'textarea',
    NUMBER = 'number',
    PILLZ = 'pillz',
}

export interface InputSpecification {
    component?: InputComponents
    label?: string
    value?: any
    typeName?: string
    filter?: (value: any) => boolean
    width?: string
    height?: string
    labelCol?: number
    wrapperCol?: number
    type?: InputType
    disabled?: boolean | ((record?: Record<string, any>) => boolean)
    mode?: SelectInputMode | true | false
    options?: Array<any>
    input?: (value: any, resourceId?: any) => void
    update?: (value: any) => void
    select?: (value: any, option: Record<string, any>) => void
    deselect?: (value: any) => void
    min?: number
    max?: number
    placeholder?: string
    help?: string | boolean | null
    extra?: string
    description?: string | boolean | (() => string)
    trueText?: string
    falseText?: string
    hidden?: boolean | ((record: Record<string, any>) => boolean)
    readonly?: boolean
    formChangeHook?: (form: Record<string, any>) => void
    notNull?: boolean
    toggle?: boolean
    uri?: string
    infinity?: boolean
    autoSize?: AutoSize
    radio?: boolean
    color?: string
    allowEmptyString?: boolean
    path?: string
    format?: string
    countable?: boolean
    chatMessage?: ChatMessage[]
    copy?: boolean
    userId?: number
    useName?: boolean
}

export type FormSpecification = {
    [resource: string]: InputSpecification
}

type AutoSize = {
    minRows: number
    maxRows: number
}

export default defineComponent({
    name: 'InputMapper',
    components: {
        FormeMissionInput,
        CrmReplacementInput,
        CrmBizmButtonInput,
        SearchAdProductInput,
        EventContentInput,
        EnumSelect,
        ProductNutrientInput,
        BooleanInput,
        ProductTagInput,
        PresetInput,
        NutrientInput,
        AutoCompleteInput,
        StandardUnitInput,
        UserInput,
        SqlEditor,
        Tiptap,
        ProductInput,
        FoodInput,
        WorkoutInput,
        MultipleProductInput,
        FormItem,
        FoodNutrientInput,
        FoodIntakeUnitInput,
        FoodIntakeInput,
        FoodIngredientInput,
        ImageInput,
        ContentImageInput,
        TemplateInput,
        ChatMessageInput,
        InputCompleteInput,
        SurveyOptionInput,
        QuestionImageInput,
        AdPropertyInput,
        AdLayoutInput,
        AdLayoutInputV2,
        AdSlotInput,
        AdEventHandlerInput,
        AdVoInput,
        AdMissionInput,
        AdTargetUserInput,
        AdScheduleInput,
        AdRequirementInput,
        WeekSelectInput,
        SegmentInput,
        FormePromptPresetInput,
    },

    setup(props: any) {
        const infinite = ref(false)
        const inputSpecRef: Ref<InputSpecification> = toRef(props, 'inputSpec')
        const setValue = (value: any) => {
            if (typeof value === 'number') {
                inputSpecRef.value.value = value as any
            } else if (inputSpecRef.value.allowEmptyString === true) {
                inputSpecRef.value.value = value
            } else {
                inputSpecRef.value.value = (value != '' && value) || null
            }
        }
        return { infinite, inputSpecRef, setValue }
    },

    props: {
        hideLabel: {
            type: Boolean,
            default: false,
        },
        inputSpec: {
            type: Object,
            required: true,
        },
        form: {
            type: Object,
        },
        prop: {
            type: String,
        },
        warning: {
            type: String,
        },
        resourceId: {
            type: [Number, String],
        },
    },

    computed: {
        value: {
            get(): any {
                const { value, mode } = this.inputSpec

                if (mode == 'tags') {
                    return value || []
                }

                if (value instanceof Function) {
                    return value()
                }

                return value
            },
            set(value: any) {
                this.setValue(value)
            },
        },
        labelCol(): number {
            if (this.inputSpec.labelCol != null) {
                return this.inputSpec.labelCol
            }
            return 4
        },
        wrapperCol(): number {
            if (this.inputSpec.wrapperCol != null) {
                return this.inputSpec.wrapperCol
            }

            if (this.hideLabel === true) {
                return 0
            }
            return 24
        },
        label(): string | undefined {
            if (this.hideLabel === true) {
                return undefined
            }
            return this.inputSpec.label
        },
        disabled() {
            if (this.inputSpec.disabled instanceof Function) {
                return this.inputSpec.disabled(this.form)
            }
            return this.inputSpec.disabled
        },
        hidden() {
            if (this.inputSpec.hidden instanceof Function) {
                return this.inputSpec.hidden(this.form)
            }
            return this.inputSpec.hidden
        },
    },

    methods: {
        sanitizeUrl(url: string) {
            return sanitizeUrl(url)
        },
        checkKeyup() {
            console.log('keyip')
            this.$emit('press-enter')
        },
        getPillzText(value: number) {
            switch (value) {
                case 1:
                    return '너무 쉬워요'
                case 2:
                    return '쉬워요'
                case 3:
                    return '괜찮아요'
                case 4:
                    return '어려워요'
                case 5:
                    return '너무 어려워요'
            }
        },
    },

    watch: {
        form: {
            deep: true,
            immediate: true,
            handler(form: Record<string, any>) {
                const formChangeHook = this.inputSpec?.formChangeHook
                if (formChangeHook) {
                    formChangeHook(form)
                }
            },
        },
    },
    emits: ['press-enter', 'update', 'remove'],
})
</script>
