<template>
    <span>
        <a-form-item v-if="mode" :name="name">
            <a-select
                :options="options"
                :value="value || []"
                :mode="mode"
                @change="change"
                @select="onSelect"
                @search="onSearch"
                :default-active-first-option="false"
                :not-found-content="null"
                :filter-option="false"
                :style="{ width: width }"
                :dropdown-match-select-width="500"
                :placeholder="placeholder"
                @press-enter="onPressEnter"
                show-search
            />
            <span v-if="countable" style="margin-left: 1em">
                <a-badge :count="count" />
            </span>
        </a-form-item>
        <a-form-item v-else :name="name">
            <a-auto-complete
                :options="options"
                :value="value"
                @change="change"
                @select="onSelect"
                @search="onSearch"
                :placeholder="placeholder"
                :style="{ width: width }"
                :dropdown-match-select-width="500"
                @press-enter="onPressEnter"
                v-bind="$attrs"
            />
            <span v-if="countable" style="margin-left: 1em">
                <a-badge :count="count" />
            </span>

            <span style="margin-left: 10px">
                <slot name="ext" />
            </span>
        </a-form-item>
    </span>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { debounce } from 'lodash-es'
import { defineComponent, Ref, ref } from 'vue'
import { SelectOption } from '@/store/enumType'
import { allowedChars } from '@/util/input-validation'

export default defineComponent({
    name: 'AutoCompleteInput',

    props: {
        uri: {
            type: String,
            required: true,
        },
        name: {
            type: String,
        },
        value: {
            type: [String, Array, Number],
        },
        mode: {
            type: String,
        },
        width: {
            type: String,
            default: '20em',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
        },
        countable: {
            type: Boolean,
            default: false,
        },
    },

    setup(props: any) {
        const options: Ref<Array<SelectOption | string>> = ref([])
        const count = ref<number>(0)
        const fetchOptions = debounce(async (text: string) => {
            //allowedChars.check(text!)
            if (text != null && text != '') {
                const response = await adminApi<
                    ApiResponse<Array<SelectOption & string>>
                >(`${props.uri}/${text}`)

                if (response.success === false)
                    throw new Error(response.errorMessage)

                if (response.result) {
                    options.value = response.result
                        ?.filter(
                            (it) =>
                                (it != null &&
                                    it.label != null &&
                                    it.label != '') ||
                                (it != null && it != '')
                        )
                        .map((it: SelectOption & string) => ({
                            label: it?.label || it,
                            value: it?.value || it,
                            bias: it?.bias || null, // 임시로 추가
                        }))
                    console.log(options.value)
                }
            }
        }, 100)
        const fetchCount = debounce(async (text: string) => {
            if (!props.countable) return
            allowedChars.check(text!)
            if (text != null && text != '') {
                const response = await adminApi<ApiResponse<number>>(
                    `${props.uri}/count/${text}`
                )

                if (response.success === false)
                    throw new Error(response.errorMessage)

                if (response.result) {
                    count.value = response.result
                }
            }
        }, 100)
        const onSearch = async (text: string) => {
            await fetchOptions(text)
            await fetchCount(text)
        }

        return { options, count, fetchOptions, fetchCount, onSearch }
    },

    emits: ['update:value', 'select'],

    methods: {
        onSelect(value: any) {
            this.$emit(
                'select',
                this.options
                    .filter(
                        (it) =>
                            it === value || (it as SelectOption).value === value
                    )
                    .pop()
            )
            this.fetchCount(value)
        },

        change(value: any) {
            this.$emit('update:value', value)
        },

        onPressEnter() {},
    },
})
</script>
