import { defineStore } from 'pinia'
import { ref } from 'vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'

export type FormeWeek = {
    weekNumber: number
    startDate: Date
    endDate: Date
    thisWeek: boolean
    hasUserFeedback: boolean
}

export const useFormeWeekStore = defineStore(
    'formeWeek',
    () => {
        const defaultWeek: FormeWeek = {
            weekNumber: 1,
            startDate: new Date(),
            endDate: new Date(),
            thisWeek: true,
            hasUserFeedback: false,
        }
        const formeWeek = ref<Record<any, any>>({
            formeWeek: [],
            selectedWeek: { ...defaultWeek },
        })

        const getWeekList = async (userId: number) => {
            const response = await adminApi<ApiResponse<any>>(
                `/api/forme/diet/week/${userId}`
            )
            formeWeek.value.formeWeek.length = 0
            formeWeek.value.formeWeek.push(...response.result)
            formeWeek.value.selectedWeek = formeWeek.value.formeWeek.find(
                (week: any) => week.thisWeek === true
            ) || {
                weekNumber: 1,
                startDate: new Date(),
                endDate: new Date(),
                thisWeek: true,
                hasUserFeedback: false,
            }
        }

        const selectWeek = (weekNumber: number | string) => {
            formeWeek.value.selectedWeek = formeWeek.value.formeWeek.find(
                (week: any) => week.weekNumber === weekNumber
            ) || { ...defaultWeek }
        }

        const cleanFormeWeek = () => {
            formeWeek.value.formeWeek.length = 0
            formeWeek.value.selectedWeek = { ...defaultWeek }
        }

        const getLastDate = () => {
            const lastWeek = formeWeek.value.formeWeek.sort(
                (a: any, b: any) => a.endDate - b.endDate
            )[formeWeek.value.formeWeek.length - 1]
            if (lastWeek) {
                return lastWeek.endDate
            }
            return null
        }

        return {
            formeWeek,
            getWeekList,
            selectWeek,
            getLastDate,
            cleanFormeWeek,
        }
    },
    {
        persist: true,
    }
)
