<script lang="tsx" setup>
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { useEnumTypeStore } from '@/store/enumType'
import { formatBoolean, formatEnum } from '@/util/formmater'
import { required } from '@/util/input-validation'
import { message } from 'ant-design-vue'
import { onMounted, ref } from 'vue'

const loading = ref(false)

const executeEvent = async (id: string) => {
    try {
        loading.value = true
        await adminApi<ApiResponse<void>>(
            `/api/forme/diet/event/execute/${id}`,
            {
                method: 'POST',
            }
        )
        message.success('실행 완료')
    } catch (error) {
        console.error(error)
        message.error('실행 실패')
    } finally {
        loading.value = false
    }
}

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 50,
        fixed: 'left',
    },
    {
        title: '이름',
        dataIndex: 'name',
        width: 150,
    },
    {
        title: '제목',
        dataIndex: 'title',
        width: 150,
    },
    {
        title: 'Phase',
        dataIndex: 'phase',
        customRender: formatEnum('forme-diet-phase'),
        width: 150,
    },
    {
        title: '생성할 Task',
        dataIndex: 'reservedTask',
        customRender: formatEnum('forme-reserved-task'),
        width: 150,
    },
    {
        title: 'Task 완료시',
        dataIndex: 'completedTask',
        customRender: formatEnum('forme-reserved-task'),
        width: 150,
    },
    {
        title: 'Task 미완료시',
        dataIndex: 'notCompletedTask',
        customRender: formatEnum('forme-reserved-task'),
        width: 150,
    },
    {
        title: 'Offset Days',
        dataIndex: 'offsetDay',
        width: 100,
        align: 'center',
    },
    {
        title: '트리거 타입',
        dataIndex: 'triggerType',
        customRender: formatEnum('forme-diet-event-trigger-type'),
        width: 100,
        align: 'center',
    },
    {
        title: '트리거 시간',
        dataIndex: 'triggerTime',
        width: 100,
        align: 'center',
    },
    {
        title: '반복 타입',
        dataIndex: 'repeatType',
        customRender: formatEnum('forme-diet-event-repeat-type'),
        width: 100,
        align: 'center',
    },
    {
        title: '반복 횟수',
        dataIndex: 'repeatCount',
        width: 50,
        align: 'center',
    },
    {
        title: '액션 타입',
        dataIndex: 'actionType',
        customRender: formatEnum('forme-diet-event-action-type'),
        width: 100,
        align: 'center',
    },
    {
        title: '활성화 여부',
        dataIndex: 'enable',
        customRender: formatBoolean,
        width: 100,
        align: 'center',
    },
    {
        title: '등록자',
        dataIndex: 'createdBy',
        width: 100,
        align: 'center',
    },
    {
        title: '수정자',
        dataIndex: 'updatedBy',
        width: 100,
        align: 'center',
    },
    {
        title: '실행',
        type: 'customAction',
        fixed: 'right',
        width: 80,
        align: 'center',
    },
]

const inputSpec: FormSpecification = {
    name: {
        label: '이름',
        value: null,
    },
    phase: {
        label: 'Phase',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-diet-phase',
    },
    reservedTask: {
        label: 'Task',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-reserved-task',
    },
    completedTask: {
        label: 'Task 완료시',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-reserved-task',
    },
    offsetDay: {
        label: 'Offset Days',
        value: null,
        component: InputComponents.AInputNumber,
    },
    triggerType: {
        label: '트리거 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-diet-event-trigger-type',
    },
    actionType: {
        label: '액션 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-diet-event-action-type',
    },
    doneTaskList: {
        label: 'task가 완료됬을시',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-reserved-task',
        mode: SelectInputMode.MULTIPLE,
    },
    notDoneTaskList: {
        label: 'task가 미완료됬을시',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-reserved-task',
        mode: SelectInputMode.MULTIPLE,
    },
    title: {
        label: '제목',
        value: null,
    },
    content: {
        label: '내용',
        value: null,
        type: InputType.TEXTAREA,
        height: '300px',
    },
    kakaoTemplateCode: {
        label: '카카오 템플릿 코드',
        value: null,
    },
    kakaoBizCode: {
        label: '카카오 비즈 코드',
        value: null,
    },
    kakaoSmsTitle: {
        label: '카카오 SMS 제목',
        value: null,
    },
    kakaoSmsContent: {
        label: '카카오 SMS 내용',
        value: null,
        type: InputType.TEXTAREA,
        height: '300px',
    },
    ownerType: {
        label: '소유자 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-diet-task-owner-type',
    },
    repeatCount: {
        label: '반복 횟수',
        value: null,
        component: InputComponents.AInputNumber,
    },
    repeatType: {
        label: '반복 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'forme-diet-event-repeat-type',
    },
    triggerTime: {
        label: '트리거 시간',
        value: null,
        component: InputComponents.ATimePicker,
    },
    enable: {
        label: '활성화 여부',
        value: null,
        component: InputComponents.BooleanInput,
        toggle: true,
    },
}

const rules = {
    name: [required],
    triggerType: [required],
    actionType: [required],
}

onMounted(() => {
    useEnumTypeStore().dispatchEnums([
        'forme-diet-event-trigger-type',
        'forme-diet-event-action-type',
        'forme-diet-task-owner-type',
        'forme-diet-event-repeat-type',
        'forme-diet-phase',
        'forme-reserved-task',
    ])
})
</script>

<template>
    <ResourceTable
        resource-name="forme-diet-event"
        title="포미 이벤트룰"
        uri="/api/forme/diet/event"
        :columns="columns"
        :create-spec="inputSpec"
        :update-spec="inputSpec"
        :create-rules="rules"
        :update-rules="rules"
        load-on-mount
        deletable
        :scroll="{ x: 1500 }"
    >
        <template #customAction="{ record }">
            <a-popconfirm
                title="정말 실행하시겠습니까?"
                @confirm="executeEvent(record.id)"
            >
                <a-button :loading="loading"> 실행 </a-button>
            </a-popconfirm>
        </template>
    </ResourceTable>
</template>
