import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, watch } from 'vue'
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import PForm from '@/components/PForm.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'

/**
 * Prompt options
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'QaTool',
  setup(__props) {

const promptOptions = ref([
    {
        label: 'userId 1234 의 토큰 알려줘',
        value: 'userId 1234 의 토큰 알려줘',
    },
    {
        label: 'userId 1234 의 전화번호 알려줘',
        value: 'userId 1234 의 전화번호 알려줘',
    },
    {
        label: 'userId 1234 의 슈가케어 무료체험권 주세요',
        value: 'userId 1234 의 슈가케어 무료체험권 주세요',
    },
    {
        label: 'userId 1234 의 상세 정보 조회해주세요',
        value: 'userId 1234 의 상세 정보 조회해주세요',
    },
    {
        label: 'userId 1234 의 백도어 아이디 만들어줘',
        value: 'userId 1234 의 백도어 아이디 만들어줘',
    },
    {
        label: 'userId 123 의 8주 리캡 만들어서 userId 456 으로 복사해줘',
        value: 'userId 123 의 8주 리캡 만들어서 userId 456 으로 복사해줘',
    },
    {
        label: '사용자 ID 추가해줘 (ID : t123454, 허용 IP: 175.113.78.31)',
        value: '사용자 ID 추가해줘 (ID : t123454, 허용 IP: 175.113.78.31)',
    },
    {
        label: '123(userId)의 웰니스 문진표 보여줘',
        value: '123(userId)의 웰니스 문진표 보여줘',
    },
])

/**
 * Access Token Spec
 */
const accessTokenInputSpec = ref<FormSpecification>({
    userId: {
        label: 'User ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    accessToken: {
        label: 'Access Token',
        value: null,
        copy: true,
    },
})

/**
 * Secure User ID Spec
 */
const secureUserIdInputSpec = ref<FormSpecification>({
    userId: {
        label: 'User ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    secureUserId: {
        label: 'Secure User ID',
        value: null,
        copy: true,
    },
})

/**
 * Chatbot Spec
 *  - Add a new 'prompt' field as a dropdown.
 *  - When 'prompt' changes, automatically set 'question'.
 */
const chatbotInputSpec = ref<FormSpecification>({
    prompt: {
        label: '프롬프트',
        value: '',
        // This should map to a Select component in your InputMapper
        component: InputComponents.ASelect,
        // (If your InputMapper expects `options` or `selectOptions`, adjust as needed)
        options: promptOptions.value,
        // Provide a placeholder if desired (depends on how InputMapper is implemented)
        placeholder: '예시 질문을 선택해주세요',
        width: '100%',
    },
    question: {
        label: 'Question',
        value: '',
        type: InputType.TEXTAREA,
    },
    answer: {
        label: 'Answer',
        value: '',
        copy: true,
        disabled: false,
        type: InputType.TEXTAREA,
        help: 'ex) 123 유저의 토큰은?, 123 유저의 상세정보, 123 유저의 전화번호, 123 유저에게 무료체험권 줘, 123 유저 강퇴, 01033340782 가진 사용자 id는?',
        height: '200px',
    },
})

/**
 * Whenever "prompt" changes, copy its value to the "question" field.
 */
watch(
    () => chatbotInputSpec.value.prompt.value,
    (newPrompt) => {
        chatbotInputSpec.value.question.value = newPrompt
    }
)

/**
 * Rules
 */
const accessTokenRules = {
    userId: {
        required: true,
    },
}

const chatbotRules = {
    question: {
        required: true,
        message: 'Question cannot be empty',
    },
}

/**
 * Loading state
 */
const loading = ref(false)

/**
 * Handlers
 */
const fetchAccessToken = async (form: Record<string, any>) => {
    loading.value = true
    const response = await adminApi<ApiResponse<string>>(
        `/api/devtool/access-token/${form.userId}`
    )
    accessTokenInputSpec.value.accessToken.value = response.result
    loading.value = false
}

const fetchSecureUserId = async (form: Record<string, any>) => {
    loading.value = true
    const response = await adminApi<ApiResponse<string>>(
        `/api/devtool/secure-user-id/${form.userId}`
    )
    secureUserIdInputSpec.value.secureUserId.value = response.result
    loading.value = false
}

const fetchChatbotAnswer = async (form: Record<string, any>) => {
    loading.value = true
    const response = await adminApi<ApiResponse<string>>(
        '/api/devtool/ask-ai',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ question: form.question }),
        }
    )
    chatbotInputSpec.value.answer.value = response.result
    loading.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_page_header, { title: "통합 QA 도구" }),
    _createVNode(_component_a_card, { title: "Access Token 조회" }, {
      default: _withCtx(() => [
        _createVNode(PForm, {
          title: "Access Token 조회",
          rules: accessTokenRules,
          "input-spec": accessTokenInputSpec.value,
          loading: loading.value,
          onSubmit: fetchAccessToken
        }, {
          button: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("토큰발급")
          ])),
          _: 1
        }, 8, ["input-spec", "loading"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_card, { title: "ChatPillyze" }, {
      default: _withCtx(() => [
        _createVNode(PForm, {
          title: "ChatPillyze",
          rules: chatbotRules,
          "input-spec": chatbotInputSpec.value,
          loading: loading.value,
          onSubmit: fetchChatbotAnswer
        }, {
          button: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("질의")
          ])),
          _: 1
        }, 8, ["input-spec", "loading"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_card, { title: "Secure User id 조회" }, {
      default: _withCtx(() => [
        _createVNode(PForm, {
          title: "Secure User id 조회",
          rules: accessTokenRules,
          "input-spec": secureUserIdInputSpec.value,
          loading: loading.value,
          onSubmit: fetchSecureUserId
        }, {
          button: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("secureUserId 조회")
          ])),
          _: 1
        }, 8, ["input-spec", "loading"])
      ]),
      _: 1
    })
  ]))
}
}

})