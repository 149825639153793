import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"inline-block","white-space":"nowrap","width":"90%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_a_select, _mergeProps({
      value: _ctx.valueByMode,
      options: _ctx.options,
      mode: _ctx.mode,
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = (value) => _ctx.$emit('update:value', value)),
      "show-search": ""
    }, _ctx.$attrs), null, 16, ["value", "options", "mode", "disabled"])
  ]))
}