<template>
    <div>
        <a-form-item :name="name">
            <codemirror
                ref="editor"
                v-model="code"
                :extensions="extensions"
                :options="editorOptions"
                :style="{
                    height,
                    width,
                }"
                @change="onChange"
            />
            <div class="flex justify-end mt-2">
                <slot name="button" />
            </div>
        </a-form-item>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps, defineEmits, toRef, computed } from 'vue'
import { Codemirror } from 'vue-codemirror'
import { MySQL, sql } from '@codemirror/lang-sql'
import { linter, Diagnostic } from '@codemirror/lint'
import { EditorView } from '@codemirror/view'

const props = defineProps({
    name: {
        type: String,
    },
    value: {
        type: String,
        default: '',
    },
    height: {
        type: String,
        default: '200px',
    },
    width: {
        type: String,
        default: '100%',
    },
    readonly: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['update:value'])

const code = toRef(props, 'value')

const editorOptions = {
    lineNumbers: true,
    tabSize: 2,
    indentWithTabs: true,
}

const extensions = computed(() => [
    sql({ dialect: MySQL }), // MySQL용 SQL 설정
    linter((view) => {
        const diagnostics: Diagnostic[] = []
        // 여기에 진단 로직을 추가하세요
        return diagnostics
    }),
    // 읽기 전용 모드로 설정
    EditorView.editable.of(!props.readonly),
])

const onChange = (value: string) => {
    code.value = value
    emits('update:value', code.value)
}

onMounted(() => {
    const editor = ref<any>(null)
})
</script>

<style scoped>
.codemirror {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}
</style>
