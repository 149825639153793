<script lang="ts" setup>
import { ref, defineProps, defineEmits } from 'vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { message } from 'ant-design-vue'
import SqlEditor from './SqlEditor.vue'
import { OptionProps } from 'ant-design-vue/es/select'

type Segment = {
    segId: number
    selectionQuery: string
    readonly: boolean
}

defineProps({
    name: {
        type: String,
    },
    value: {
        type: String,
        required: true,
    },
})

const segment = ref<Segment>({
    segId: 0,
    selectionQuery: '',
    readonly: true,
})

const fetchSegment = async (id: string) => {
    try {
        segment.value.readonly = false
        const response = await adminApi<ApiResponse<Segment>>(
            `/api/segments/${id}`
        )
        segment.value = response.result || {
            segId: 0,
            selectionQuery: '',
            readonly: true,
        }
    } catch (error) {
        console.error(error)
        message.error('세그먼트 정보를 불러오는데 실패했습니다.')
    }
}

const emits = defineEmits(['update:value'])

const segIdList = ref<OptionProps[]>([])

const onSearch = async (value: string) => {
    if (value.length === 0) {
        return
    }
    segIdList.value.length = 0
    const response = await adminApi<ApiResponse<string[]>>(
        `/api/segments/search/${value}`
    )
    segIdList.value.push(
        ...(response.result || []).map((id) => ({
            value: id,
            label: id,
        }))
    )
}

const onSelect = async (value: string) => {
    if (value) {
        emits('update:value', value)
        await fetchSegment(value)
    }
}

const queryResult = ref<string>('')
const loadingQuery = ref<boolean>(false)
const onQuery = async () => {
    loadingQuery.value = true
    try {
        const response = await adminApi<ApiResponse<string[]>>(
            `/api/segments/query/${segment.value.segId}`,
            {
                method: 'POST',
            }
        )
        queryResult.value = response.result?.join(', ') || ''
    } catch (error) {
        console.error(error)
        message.error('쿼리 실행에 실패했습니다.')
    } finally {
        loadingQuery.value = false
    }
}
</script>

<template>
    <a-space direction="vertical" class="w-[100%]">
        <a-auto-complete
            :options="segIdList"
            @select="onSelect"
            @search="onSearch"
        />
        <SqlEditor :value="segment.selectionQuery" :readonly="segment.readonly">
            <template #button>
                <a-button
                    type="primary"
                    @click="onQuery"
                    :loading="loadingQuery"
                    >실행</a-button
                >
            </template>
        </SqlEditor>
        <a-typography-text type="secondary" class="mt-2"
            >쿼리 결과로 userId 목록을 추출합니다.</a-typography-text
        >
        <a-textarea :value="queryResult" />
    </a-space>
</template>
